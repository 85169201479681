import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1465db2c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "normal-dialog"
};
const _hoisted_2 = {
  class: "normal-dialog-title"
};
const _hoisted_3 = {
  class: "normal-dialog-content"
};
const _hoisted_4 = {
  class: "ormal-dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: _ctx.show,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => _ctx.show = $event),
    showConfirmButton: false
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, () => [_createTextVNode(_toDisplayString(_ctx.title), 1)], true)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "content", {}, () => [_createTextVNode(_toDisplayString(_ctx.message), 1)], true)]), _createElementVNode("div", _hoisted_4, [_ctx.showCancelButton ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "ormal-dialog-btn cancel",
      onClick: _cache[0] || (_cache[0] = function () {
        return _ctx.handleCancel && _ctx.handleCancel(...arguments);
      })
    }, _toDisplayString(_ctx.cancelButtonText || _ctx.t('cCustomDialog.取消')), 1)) : _createCommentVNode("", true), _ctx.showConfirmButton ? (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: "ormal-dialog-btn ok",
      onClick: _cache[1] || (_cache[1] = function () {
        return _ctx.handleOk && _ctx.handleOk(...arguments);
      })
    }, _toDisplayString(_ctx.confirmButtonText || _ctx.t('cCustomDialog.确认')), 1)) : _createCommentVNode("", true)])])]),
    _: 3
  }, 8, ["show"]);
}