import network from '@/utils/network';

// 获取钻石数目
export function handleGetStoneNumber() {
  return network.post('/point/stone/get-number');
}

// 私照消费钻石
export function handleConsumeStoneByPrivatePhoto(params) {
  return network.post('/point/stone/consume-by-private-photo', params, {}, true);
}

// 通过消息消费钻石
export function handleConsumeStoneByMsg(params) {
  return network.post('/point/stone/consume-by-message', params);
}

// 音视频检查钻石是否足够
export function handleCheckoutIfAllowCall(params) {
  return network.post('/point/stone/allow-call', params);
}

// 音视频通话扣钻石
export function handleConsumeStoneByCalling(params) {
  return network.post('/point/stone/consume', params, {}, true);
}
