import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ee80a7de"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tool-bar-by-video"
};
const _hoisted_2 = {
  class: "ctb-tool-emoji"
};
const _hoisted_3 = {
  class: "tool-bar-by-video-tools"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tool-bar-by-video-input"
};
const _hoisted_6 = {
  class: "input-bar-by-video"
};
const _hoisted_7 = {
  class: "ctb-input"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_emoji_picker = _resolveComponent("emoji-picker");
  const _component_icon_font = _resolveComponent("icon-font");
  const _component_van_field = _resolveComponent("van-field");
  const _component_call_opecation_by_video_close = _resolveComponent("call-opecation-by-video-close");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(_component_emoji_picker, {
    theme: "dark",
    "disabled-groups": ['animals_nature', 'food_drink', 'activities', 'travel_places', 'objects', 'symbols', 'flags'],
    "disable-skin-tones": "",
    "hide-search": "",
    "hide-group-names": "",
    "hide-group-icons": "",
    native: "",
    onSelect: _ctx.handleSelectEmoji
  }, null, 8, ["onSelect"])], 512), [[_vShow, _ctx.isEmojExpand]]), _withDirectives(_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toolIcons, item => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['ctb-icon-circel', item === 'microphone' && _ctx.isCloseMicrophone || item === 'camera' && _ctx.isInvertCamera ? 'selected' : '']),
      key: item,
      onClick: $event => _ctx.handleTriggerToolItem(item)
    }, [_createVNode(_component_icon_font, {
      "icon-name": item
    }, null, 8, ["icon-name"])], 10, _hoisted_4);
  }), 128))], 512), [[_vShow, !_ctx.isInputExpand]]), _withDirectives(_createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: "ctb-icon-circel",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.isInputExpand = false)
  }, [_createVNode(_component_icon_font, {
    class: "ctb-icon-keyboard",
    "icon-name": "keyboard"
  })]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_van_field, {
    modelValue: _ctx.inputText,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.inputText = $event),
    placeholder: _ctx.t('chat.请输入')
  }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", {
    class: "ctb-send-button",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.handleSendMessage && _ctx.handleSendMessage(...arguments);
    })
  })])], 512), [[_vShow, _ctx.isInputExpand]]), _createVNode(_component_call_opecation_by_video_close, {
    class: _normalizeClass(!_ctx.isInputExpand ? 'ctb-small-close' : 'ctb-big-close'),
    onOnHangupCall: _ctx.handleHangupCall
  }, null, 8, ["class", "onOnHangupCall"])]);
}