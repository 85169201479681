import { makeI18nT } from '@/languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
const defaultDefinedProps = {
  visible: {
    type: Boolean,
    default: false
  },
  showConfirmButton: {
    type: Boolean,
    default: true
  },
  confirmButtonText: {
    type: String,
    default: ''
  },
  cancelButtonText: {
    type: String,
    default: ''
  },
  showCancelButton: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  },
  message: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Boolean,
    default: false
  }
};
export default defineComponent({
  name: 'normal-dialog',
  props: defaultDefinedProps,
  emits: ['update:modelValue', 'cancel', 'ok'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const show = ref(props.visible);
    watchEffect(() => {
      show.value = props.visible;
    });
    watchEffect(() => {
      emit('update:modelValue', show.value);
    });
    const {
      t
    } = makeI18nT('cCustomDialog');
    return {
      show,
      t
    };
  },
  methods: {
    handleCancel() {
      this.show = false;
      this.$emit('cancel');
    },
    handleOk() {
      this.show = false;
      this.$emit('ok');
    }
  }
});