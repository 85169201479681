export default {
  举报: '举报',
  拉黑: '拉黑',
  在线: '在线',
  个人信息: '个人信息',
  最近上线时间: '最近上线时间',
  恋爱观念: '恋爱观念',
  交友目的: '交友目的',
  职业: '职业',
  生日: '生日',
  身高: '身高',
  体重: '体重',
  相册: '相册',
  视频: '视频',
  打招呼: '打招呼',
  发消息: '发消息',
  视频通话: '视频通话',
  Hi: '你好',
  开通VIP可查看: '开通VIP可查看全部信息',
  开通Vip: '去开通',
  拉黑提示: '拉黑成功',
  拉黑文案: '确定拉黑吗？他将不能看到你的个人信息',
};
