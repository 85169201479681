import NormalDialog from './components/NormalDialog.vue';
import LeaveVipDialog from './components/LeaveVipDialog.vue';
import SayHiLimitDialog from './components/SayHiLimitDialog.vue';
import LeaveDiamondDialog from './components/LeaveDiamondDialog.vue';

import { handleTriggerRenderToBody } from '@/utils/utils';

export function customShowConfirmDialog(options) {
  return new Promise((resolve, reject) => {
    const closer = handleTriggerRenderToBody(NormalDialog, {
      ...options,
      visible: true,
      onCancel: () => reject() || closer(),
      onOk: () => resolve() || closer(),
    });
  });
}

export function handleTriggerLeaveVipDialog(options = {}, id = undefined) {
  return new Promise((resolve, reject) => {
    const closer = handleTriggerRenderToBody(LeaveVipDialog, {
      ...options,
      visible: true,
      onCancel: () => reject() || closer(),
      onOk: () => resolve() || closer(),
    }, id);
  });
}

export function handleTriggerLeaveDiamondDialog(options = {}, id = undefined) {
  return new Promise((resolve, reject) => {
    const closer = handleTriggerRenderToBody(LeaveDiamondDialog, {
      ...options,
      visible: true,
      onCancel: () => reject() || closer(),
      onOk: () => resolve() || closer(),
    }, id);
  });
}

export function handleTriggerSayHiLimitDialog(options) {
  return new Promise((resolve, reject) => {
    const closer = handleTriggerRenderToBody(SayHiLimitDialog, {
      ...options,
      visible: true,
      onCancel: () => reject() || closer(),
      onOk: () => resolve() || closer(),
    });
  });
}
