import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bea826c6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-opecation-by-video-close"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "cobvc-item reject",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleHangupCall && _ctx.handleHangupCall(...arguments);
    })
  })]);
}