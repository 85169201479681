import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_view_wrap = _resolveComponent("view-wrap");
  const _component_router_view = _resolveComponent("router-view");
  const _component_global_rtc_audio = _resolveComponent("global-rtc-audio");
  const _component_global_rtc_video = _resolveComponent("global-rtc-video");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
    default: _withCtx(_ref => {
      let {
        Component
      } = _ref;
      return [_createVNode(_component_view_wrap, null, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.fullPath
        })) : _createCommentVNode("", true)]),
        _: 2
      }, 1024)];
    }),
    _: 1
  }), _createVNode(_component_global_rtc_audio, {
    ref: "RtcAudioDOM"
  }, null, 512), _createVNode(_component_global_rtc_video, {
    ref: "RtcVideoDOM"
  }, null, 512)], 64);
}