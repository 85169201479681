/**
 * 本地 indexdb 数据库，进行必要的少量信息存储
 *
 * 1. 网易云信云端会话列表是付费 api、这里存储在本地。对于应用影响较小，本地存储出问题，换用云端会话列表即可
 *
 * 2. 需要特殊处理消息标记，消息记录使用云端接口，不支持编辑修改，对于需要特殊处理的消息，在这里进行标记，相对存储聊天记录，数据量不会太大。
 *    如果出现问题，可能会影响聊天记录（问答、索要礼物），但是影响较小
 *
 * 3. 表结构如下
 *
 * 消息标记列表: messages
 * ===============================================================
 * idClient        varchar  path-key           消息 idClient
 * action          varchar  index              消息操作行为  hidden -> 隐藏该消息、ext -> 扩展该消息、violation -> 违规消息拦截
 * payload         text                        荷载数据
 * updateTime      int                         更新时间
 *
 * 会话列表: sessions
 * ===============================================================
 * ...云信会话相关的字段
 */

import { CTIndexedDB } from './indexdb';
import { AUTHORIZATION } from '@/utils/consts';
import { getUserInfoFromStorage } from '@/utils/utils';

function handleGenerateDatabaseSetting(imId) {
  return {
    version: 10,
    database: `c-im-database-${imId}`,
    stores: [{
      name: 'sessions',
      keyPath: 'id',
      cursorIndex: [
        { name: 'id', unique: true },
      ],
    }, {
      name: 'messages',
      keyPath: 'idClient',
      cursorIndex: [
        { name: 'idClient', unique: true },
        { name: 'action', unique: false },
      ],
    }],
  };
}

let instance = null;

export function initIMIndexdb() {
  const userInfo = getUserInfoFromStorage();
  const token = window.localStorage.getItem(AUTHORIZATION) || '';

  // 用户未登陆 -> 不初始化 IM 数据库
  if (!token || Object.keys(userInfo).length === 0 || instance) {
    return;
  }

  instance = new CTIndexedDB(handleGenerateDatabaseSetting(userInfo.imId));
}

export function getIMIndexdb() {
  return instance;
}
