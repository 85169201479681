/* eslint-disable no-return-assign */
import CoreWYBase from './CoreWYBase';

let instance = null;

export function getImHandlerInstance() {
  return instance || (instance = new CoreWYBase());
}

export function init(options) {
  getImHandlerInstance().init(options);
}
