export default {
  标题: '账号和安全',
  账号: '账号',
  密码: '密码',
  修改密码: '修改密码',
  Facebook: 'Facebook',
  Google: 'Google',
  绑定: '关联',
  未绑定: '未关联',
  账号已绑定: '已绑定{platform}账号',
};
