export default {
  标题: 'वीआईपी प्राप्त करें',
  解锁聊天: 'चैट प्रतिबंध अनलॉक करें',
  解锁聊天描述: 'किसी से भी अनलिमिटेड चैटिंग',
  解锁视频: 'कॉल तक पहुंच',
  解锁视频描述: 'कॉल के लिए हीरे खरीदें',
  提升质量: 'गुणवत्ता उपयोगकर्ता अनुशंसा',
  提升质量描述: 'तुम्हें बेहतर और अधिक उत्साही लड़की की अनुशंसा करता हूँ',
  展示Logo: 'वीआईपी विशिष्ट लोगो',
  展示Logo描述: 'अधिक दिलचस्प लोगों को आपको ढूंढने दें',
  个人信息: 'सभी व्यक्तिगत जानकारी अनलॉक करें',
  解锁个人信息描述: 'गुप्त एल्बम और वीडियो देख सकते हैं',
  会员特权: 'सदस्य विशेषाधिकार',
};
