export default {
  标题: 'Mine',
  消息阅读: 'new messages, click to read!',
  会员: 'Vip',
  钻石: 'Diamond',
  访客: 'visitors',
  设置: 'Setting',
  会员截止: 'membeiship deadine {time}',
  修改头像提示: 'modify avatar success',
};
