import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fb570252"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-opecation"
};
const _hoisted_2 = {
  class: "call-opecation-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.statusText), 1), _ctx.status === 'initiate' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['call-opecation-content', _ctx.status])
  }, [_createElementVNode("div", {
    class: "call-opecation-item reject",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleCancelInvite && _ctx.handleCancelInvite(...arguments);
    })
  })], 2)) : _createCommentVNode("", true), _ctx.status === 'invited' ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(['call-opecation-content', _ctx.status])
  }, [_createElementVNode("div", {
    class: "call-opecation-item reject",
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.handleRejectInvited && _ctx.handleRejectInvited(...arguments);
    })
  }), _createElementVNode("div", {
    class: _normalizeClass(['call-opecation-item', 'resolve', _ctx.type]),
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.handleReciveInvited && _ctx.handleReciveInvited(...arguments);
    })
  }, null, 2)], 2)) : _createCommentVNode("", true), _ctx.status === 'calling' ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(['call-opecation-content', _ctx.status])
  }, [_createElementVNode("div", {
    class: _normalizeClass(['call-opecation-item', 'voice', _ctx.params.openVoice ? 'selected' : '']),
    onClick: _cache[3] || (_cache[3] = $event => _ctx.handleTriggerVoiceChange(_ctx.params.openVoice))
  }, null, 2), _createElementVNode("div", {
    class: "call-opecation-item reject",
    onClick: _cache[4] || (_cache[4] = function () {
      return _ctx.handleHangupCall && _ctx.handleHangupCall(...arguments);
    })
  }), _createElementVNode("div", {
    class: _normalizeClass(['call-opecation-item', 'microphone', _ctx.params.closeMicrophone ? 'selected' : '']),
    onClick: _cache[5] || (_cache[5] = $event => _ctx.handleTriggerMicrophoneVoiceChange(_ctx.params.closeMicrophone))
  }, null, 2)], 2)) : _createCommentVNode("", true)]);
}