import { defineComponent, ref } from 'vue';
import { makeI18nT } from '@/languages/index';
import { IMG_RESOURCE_DOMAIN } from '@/utils/consts';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
export default defineComponent({
  name: 'say-hi-limit-modal',
  setup() {
    const visible = ref(true);
    const {
      t
    } = makeI18nT('cCustomDialog');
    useLockBodyScroll(visible);
    return {
      visible,
      IMG_RESOURCE_DOMAIN,
      t
    };
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleOk() {
      this.$emit('ok');
    }
  }
});