export default {
  标题: 'सेटिंग',
  账号和安全: 'खाता और सुरक्षा',
  关于: 'के बारे में Bliss Live',
  评分: 'हमें रेटिंग दें',
  黑名单: 'अवरुद्ध सूची',
  切换用户: 'उपयोगकर्ता बदलें',
  切换用户弹窗标题: 'खाता स्थानांतरित करें',
  切换用户弹窗内容: 'दोबारा लॉगिन करने के लिए कृपया अपना आईडी और पासवर्ड सेव करें',
  切换用户弹窗取消: 'रद्द करना',
  切换用户弹窗确认: 'पुष्टि करना',
};
