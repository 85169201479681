import axios from 'axios';
import { showFailToast } from 'vant';
import { AUTHORIZATION, IM_AUTHORIZATION } from '@/utils/consts';
import { getTouristUniqueDeviceId } from '@/providers/touristAccount';
import { getSystemLanguage, handleFocusJump, getPlatformType } from '@/utils/utils';

function handleResponseFail(error) {
  return showFailToast({ message: error.message, wordBreak: 'break-word' }) && Promise.reject(error);
}

function handleResponseSuccess(response, silence) {
  // token失效 -> 登陆
  if (response.data.code === 1 && window.location.pathname !== '/sign-up') {
    localStorage.removeItem(AUTHORIZATION);
    localStorage.removeItem(IM_AUTHORIZATION);

    handleFocusJump('/sign-up');
    return false;
  }
  // 账号被封禁 -> 禁用
  if (response.data.code === 47) {
    return false;
  }
  // 异常
  if (response.data.code !== 0) {
    return silence ? response.data : (showFailToast({ message: response.data.msg, wordBreak: 'break-word' }) && false);
  }
  // ok
  return response.data;
}

function handleGetXUserAgent() {
  const platformType = getPlatformType();

  // eslint-disable-next-line no-nested-ternary
  const platformTypeText = platformType === 'android' ? 'Android' : (platformType === 'ios' ? 'IOS' : 'PC');

  return `${process.env.VUE_APP_AGENT} (${platformTypeText} 1.0.0)`;
}

export function getRequestHeaders() {
  return {
    'x-user-agent': handleGetXUserAgent(),
    'Content-type': 'application/json',
    'x-device-id': getTouristUniqueDeviceId(),
    lang: getSystemLanguage(),
    authorization: window.localStorage.getItem(AUTHORIZATION) || '',
  };
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN,
  timeout: 60 * 1000,
  headers: {
    lang: getSystemLanguage(), 'x-user-agent': handleGetXUserAgent(),
  },
});

instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    'Content-type': 'application/json',
    'x-device-id': getTouristUniqueDeviceId(),
    authorization: window.localStorage.getItem(AUTHORIZATION) || '',
    ...config.headers,
  };

  // eslint-disable-next-line no-param-reassign
  config.data = config.data || {};

  return config;
}, (error) => Promise.reject(error));

const network = {};

// eslint-disable-next-line func-names
network.post = function (url, data, options = {}, silence = false) {
  return instance.post(url, data, options).then((response) => handleResponseSuccess(response, silence), handleResponseFail);
};

// eslint-disable-next-line func-names
network.get = function (url, params, options = {}, silence = false) {
  return instance.get(url, { params, ...options }).then((response) => handleResponseSuccess(response, silence), handleResponseFail);
};

export default network;
