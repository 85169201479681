export default {
  标题: 'पासवर्ड बदलें',
  保存: 'बचाना',
  新密码: 'नया पासवर्ड',
  输入密码占位文案: 'कृपया पासवर्ड डालें',
  密码提醒: '6-16 अंकों का पासवर्ड, संख्याएँ या अक्षर',
  确认密码: 'नये पासवर्ड की पुष्टि करें',
  确认密码描述: 'नया पासवर्ड कन्फर्म पासवर्ड के समान ही होना चाहिए',
  密码不同: 'पासवर्ड अलग है',
  密码为空: 'पासवर्ड खाली है',
  密码格式错误: 'पासवर्ड गलत है',
  修改成功: 'सफलता बदलें',
};
