import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-05f1a911"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "target-user-avatar"
};
const _hoisted_2 = {
  class: "tua-avatar"
};
const _hoisted_3 = {
  class: "tua-user-info"
};
const _hoisted_4 = {
  class: "tua-user-name"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "tua-user-detail"
};
const _hoisted_8 = {
  class: "tua-user-tag gender"
};
const _hoisted_9 = {
  class: "tua-user-tag height"
};
const _hoisted_10 = {
  class: "tua-user-tag address"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$targetUserInfo$l, _ctx$targetUserInfo$l2;
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
    width: "2.4rem",
    height: "2.4rem",
    round: "",
    fit: "cover",
    src: _ctx.targetUserInfo.icon,
    alt: "avatar"
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.targetUserInfo.nickname), 1), _createElementVNode("img", {
    class: "tua-user-flag",
    src: require('@/images/chat/chat_rtc_icon.png'),
    alt: ""
  }, null, 8, _hoisted_5), _ctx.targetUserInfo.levelData && (_ctx$targetUserInfo$l = _ctx.targetUserInfo.levelData) !== null && _ctx$targetUserInfo$l !== void 0 && _ctx$targetUserInfo$l.smallIcon ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "tua-user-level",
    src: (_ctx$targetUserInfo$l2 = _ctx.targetUserInfo.levelData) === null || _ctx$targetUserInfo$l2 === void 0 ? void 0 : _ctx$targetUserInfo$l2.smallIcon,
    alt: "level"
  }, null, 8, _hoisted_6)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.targetUserInfo.age), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.targetUserInfo.height || '-') + " cm", 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.address), 1)])]);
}