/* eslint-disable global-require */
import { createI18n } from 'vue-i18n';
import { getSystemLanguage } from '@/utils/utils';

const languageMessagesMap = {
  'zh-CN': {
    // 页面
    hot: require('./zh-CN/hot').default,
    anchor: require('./zh-CN/anchor').default,
    login: require('./zh-CN/login').default,
    signUp: require('./zh-CN/sign-up').default,
    initedCompletion: require('./zh-CN/inited-completion').default,
    initedAvatar: require('./zh-CN/inited-avatar').default,
    messages: require('./zh-CN/messages').default,
    member: require('./zh-CN/member').default,
    diamonds: require('./zh-CN/diamonds').default,
    report: require('./zh-CN/report').default,
    mine: require('./zh-CN/mine').default,
    setting: require('./zh-CN/setting').default,
    account: require('./zh-CN/account').default,
    about: require('./zh-CN/about').default,
    blockList: require('./zh-CN/block-list').default,
    changePassword: require('./zh-CN/change-password').default,
    visitor: require('./zh-CN/visitor').default,
    systemNot: require('./zh-CN/system-not').default,
    uploadPhoto: require('./zh-CN/upload-photo').default,
    editInfomation: require('./zh-CN/edit-infomation').default,
    chatMore: require('./zh-CN/chat-more').default,
    chat: require('./zh-CN/chat').default,

    // 组件
    cTabBar: require('./zh-CN/c-tab-bar').default,
    cScrollList: require('./zh-CN/c-scroll-list').default,
    cPictureUpload: require('./zh-CN/c-picture-upload').default,
    cPayPopup: require('./zh-CN/c-pay-popup').default,
    cCustomDialog: require('./zh-CN/c-custom-dialog').default,
    cGiftPopup: require('./zh-CN/c-gift-popup').default,
    cProposeCall: require('./zh-CN/c-propose-call').default,
    cEmptyPlaceholder: require('./zh-CN/c-empty-placeholder').default,
    cAddToHomeScreen: require('./zh-CN/c-add-to-home-screen').default,
    cRateModal: require('./zh-CN/c-rate-modal').default,
  },
  'en-US': {
    // 页面
    hot: require('./en-US/hot').default,
    anchor: require('./en-US/anchor').default,
    login: require('./en-US/login').default,
    signUp: require('./en-US/sign-up').default,
    initedCompletion: require('./en-US/inited-completion').default,
    initedAvatar: require('./en-US/inited-avatar').default,
    messages: require('./en-US/messages').default,
    member: require('./en-US/member').default,
    diamonds: require('./en-US/diamonds').default,
    report: require('./en-US/report').default,
    mine: require('./en-US/mine').default,
    setting: require('./en-US/setting').default,
    account: require('./en-US/account').default,
    about: require('./en-US/about').default,
    blockList: require('./en-US/block-list').default,
    changePassword: require('./en-US/change-password').default,
    visitor: require('./en-US/visitor').default,
    systemNot: require('./en-US/system-not').default,
    uploadPhoto: require('./en-US/upload-photo').default,
    editInfomation: require('./en-US/edit-infomation').default,
    chatMore: require('./en-US/chat-more').default,
    chat: require('./en-US/chat').default,

    // 组件
    cTabBar: require('./en-US/c-tab-bar').default,
    cScrollList: require('./en-US/c-scroll-list').default,
    cPictureUpload: require('./en-US/c-picture-upload').default,
    cPayPopup: require('./en-US/c-pay-popup').default,
    cCustomDialog: require('./en-US/c-custom-dialog').default,
    cGiftPopup: require('./en-US/c-gift-popup').default,
    cProposeCall: require('./en-US/c-propose-call').default,
    cEmptyPlaceholder: require('./en-US/c-empty-placeholder').default,
    cAddToHomeScreen: require('./en-US/c-add-to-home-screen').default,
    cRateModal: require('./en-US/c-rate-modal').default,
  },
  'hi-IN': {
    login: require('./hi-IN/login').default,
    signUp: require('./hi-IN/sign-up').default,
    initedCompletion: require('./hi-IN/inited-completion').default,
    about: require('./hi-IN/about').default,
    account: require('./hi-IN/account').default,
    hot: require('./hi-IN/hot').default,
    messages: require('./hi-IN/messages').default,
    mine: require('./hi-IN/mine').default,
    member: require('./hi-IN/member').default,
    diamonds: require('./hi-IN/diamonds').default,
    visitor: require('./hi-IN/visitor').default,
    setting: require('./hi-IN/setting').default,
    blockList: require('./hi-IN/block-list').default,
    changePassword: require('./hi-IN/change-password').default,
    uploadPhoto: require('./hi-IN/upload-photo').default,
    editInfomation: require('./hi-IN/edit-infomation').default,
    systemNot: require('./hi-IN/system-not').default,
    anchor: require('./hi-IN/anchor').default,
    report: require('./hi-IN/report').default,
    chatMore: require('./hi-IN/chat-more').default,
    chat: require('./hi-IN/chat').default,

    cTabBar: require('./hi-IN/c-tab-bar').default,
    cScrollList: require('./hi-IN/c-scroll-list').default,
    cGiftPopup: require('./hi-IN/c-gift-popup').default,
    cCustomDialog: require('./hi-IN/c-custom-dialog').default,
    cEmptyPlaceholder: require('./hi-IN/c-empty-placeholder').default,
    cAddToHomeScreen: require('./hi-IN/c-add-to-home-screen').default,
    cRateModal: require('./hi-IN/c-rate-modal').default,
    cPictureUpload: require('./hi-IN/c-picture-upload').default,
    cPayPopup: require('./hi-IN/c-pay-popup').default,
    cProposeCall: require('./hi-IN/c-propose-call').default,
  },
};

export function scanfLanguageMessages(viewModule) {
  const subLanguageMessagesMap = {};
  const modules = [].concat(viewModule);

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const prop in languageMessagesMap) {
    subLanguageMessagesMap[prop] = {};

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const subProp in languageMessagesMap[prop]) {
      if (modules.indexOf(subProp) > -1) {
        modules.forEach((item) => {
          subLanguageMessagesMap[prop][item] = languageMessagesMap[prop][item];
        });
      }
    }
  }

  return subLanguageMessagesMap;
}

export function makeI18nT(viewModule) {
  const { global } = createI18n({
    legacy: false, locale: getSystemLanguage(), messages: scanfLanguageMessages(viewModule),
  });
  return global;
}
