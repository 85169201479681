import { SUCCESS } from '@/database/indexdb';
import { events, notifyEnum } from './config';
import { handleTryParseJSON } from '@/utils/utils';
import { custemMessageType } from '@/views/chat/config/index';
import { FBR_EVENTS, FBReporter, FIREBASE_EVENTS, FireBaseReporter, KKReporter, KK_EVENTS } from '@/providers/reporter';

export default class CoreIM {
  // 初始化 IM sessions 会话
  handleInitLocalSessions() {
    return this.handleGetLocalSessions(1000).then((sessions) => {
      this.sessions = sessions;
      this.handleDispatchCustomEvent(events.IM_UPDATE_SESSIONS, this.sessions);
    });
  }

  // 查询本地会话列表
  handleGetLocalSessions(limit) {
    return this.database
      .then((db) => db.getAllData('sessions'))
      .then((result) => (result.code === SUCCESS ? result.data.slice(0, limit) : []))
      .then((result) => result.sort((a, b) => (b.updateTime - a.updateTime)));
  }

  handleWillReconnect() {
    console.log('im will reconnect');
    KKReporter(KK_EVENTS.IM_WILL_RECONNECTING, { message: 'im will reconnect' });
  }

  // 离线消息
  handleOfflineMsgCallback(session) {
    console.log('接收到离线消息', session);

    KKReporter(KK_EVENTS.IM_OFFLINE_MESSAGES, { session });
    (session.msgs || []).forEach((item) => this.handleReceiveAllTypeMessage(item));
  }

  // 接收消息回调
  handleMsgCallback(message) {
    console.log('接收到消息', message);
    this.handleReceiveAllTypeMessage(message);
  }

  // 处理接收到所有类型的消息
  handleReceiveAllTypeMessage(message) {
    // 音视频策略信消息 -> 调起全局通话
    this.handleAudioOrVideoFilter(message);

    // 服务端礼物消息 -> 过滤该消息
    if (this.handleAskGiftMsgByServerFilter(message)) {
      return;
    }

    // 直播间消息处理 -> 拦截 -> 存入直播间消息列表
    if (this.handleLiveMessageFilter(message)) {
      this.handleSendOrReceiveMessageAfterHooks(message, true);
      return;
    }

    // 上报通知消息
    if (this.handleAskReportMsgFromServer(message)) {
      return;
    }

    // 当前聊天对象的消息 -> 记录到聊天列表
    if (message.target === this.targetAccountId) {
      this.handleSendOrReceiveMessageAfterHooks(message);
    }
  }

  // 音视频策略信消息
  handleAudioOrVideoFilter(message) {
    if (message.type !== 'custom') {
      return;
    }

    const content = handleTryParseJSON(message.content);

    // 带封面的视频策略信
    if (custemMessageType.AUTO_PLAY_VIDEO === content.type.toString()) {
      this.handleDispatchCustomEvent(events.XL_FAKE_RECIVE_VIDEO_INVITED, message.to, content.data.videoUrl, '');
    }

    // 语音、视频策略信
    if (custemMessageType.RECIVED_AUDIO_OR_VIDEO === content.type.toString()) {
      this.handleDispatchCustomEvent(content.data.callType === 2 ? events.XL_FAKE_RECIVE_VIDEO_INVITED : events.XL_FAKE_RECIVE_VOICE_INVITED, message.to, '', content.data.videoUrl);
    }
  }

  // 服务端礼物消息 -> 过滤该消息
  handleAskGiftMsgByServerFilter(message) {
    if (message.type !== 'custom') {
      return false;
    }

    const content = handleTryParseJSON(message.content);
    const customInfo = handleTryParseJSON(message.custom || null);

    const result = custemMessageType.ASK_GIFT === content.type.toString() && customInfo.fromType?.toString() === '2';

    return result;
  }

  // 服务端上报消息
  handleAskReportMsgFromServer(message) {
    if (message.type !== 'custom') {
      return false;
    }

    const content = handleTryParseJSON(message.content);

    if (custemMessageType.REPORT_NOTICE !== content.type.toString()) {
      return false;
    }

    // pixel 上报
    if (content.data.name === 'purchase' && content.data.paySuccess) {
      FBReporter(FBR_EVENTS.Purchase, content.data.property || {});
      FBReporter(FBR_EVENTS.CustomPurchase, content.data.property || {}, true);

      KKReporter(KK_EVENTS.PURCHASE, content.data.property || {});
    }

    // firebase 上报
    if (content.data.firebase && content.data.name === 'purchase' && content.data.paySuccess) {
      FireBaseReporter(FIREBASE_EVENTS.Purchase, content.data.property || {});
      FireBaseReporter(FIREBASE_EVENTS.CustomPurchase, content.data.property || {});
    }
    return true;
  }

  // 解析直播消息
  handleLiveMessageFilter(message) {
    const customInfo = handleTryParseJSON(message.custom || null);
    return customInfo.isFilter === '1' && this.invitedInfos.channelInfo;
  }

  // 重置直播消息列表
  handleResetLiveMessages() {
    this.liveMessages = [];
    this.handleDispatchCustomEvent(events.IM_UPDATE_LIVE_MESSAGES, this.liveMessages);
  }

  // 收到消息后置处理
  handleSendOrReceiveMessageAfterHooks(message, isLiveMsg = false) {
    this[isLiveMsg ? 'liveMessages' : 'messages'].push(message);

    if (isLiveMsg) {
      this.handleDispatchCustomEvent(events.IM_UPDATE_LIVE_MESSAGES, this.liveMessages);
      return;
    }
    this.handleUpdateFlagMessages().then(() => this.handleDispatchCustomEvent(events.IM_UPDATE_MESSAGES, this.messages));
  }

  // 收到消息之后，在更新 messages 之前 更新 flagMessages
  handleUpdateFlagMessages() {
    return this.handleGetLocalFlagMessage().then((messages) => {
      this.flagMessages = messages;
    });
  }

  // 获取标记消息
  handleGetLocalFlagMessage(action) {
    if (typeof action === 'undefined') {
      return this.database.then((db) => db.getAllData('messages')).then((result) => (result.code === SUCCESS ? result.data : []));
    }
    return this.database.then((db) => db.getAllData('messages'))
      .then((result) => (result.code === SUCCESS ? result.data : []))
      .then((datas) => datas.filter((item) => item.action === action));
  }

  // 通过 idClient 获取标记消息
  handleGetLocalFlagMessageByClientId(idClient) {
    return this.database.then((db) => db.getData('messages', 'idClient', idClient)).then((result) => result.data);
  }

  // 最近会话回调
  handleSessionsCallback(sessions) {
    this.handleUpdateSessions(sessions);
  }

  // 会话更新回调
  handleUpdateSessionCallback(session) {
    console.log('会话更新回调: ', session);

    // 过滤不合规的消息
    if (typeof session.lastMsg.idServer === 'undefined' || session.lastMsg.idServer === '0') {
      return;
    }

    // 服务器推送礼物消息
    if (this.handleAskGiftMsgByServerFilter(session.lastMsg)) {
      return;
    }

    // 直播间消息不计入本地会话
    if (this.handleLiveMessageFilter(session.lastMsg)) {
      return;
    }
    this.handleUpdateSessions([session]);
  }

  // 处理系统通知
  handleCustomSysMsgCallback(data) {
    const content = handleTryParseJSON(data.content);

    // 文本消息不合规
    if (content.id === notifyEnum.IM_TEXT_VIOLATION) {
      this.handleViolationTextMessage(content);
    }
  }

  // 文本消息不合规
  handleViolationTextMessage(content) {
    const targetMsgId = content.data.realMsgUuid || content.data.targetMsgId;

    return this.handleCreateLocalFlagMessage(targetMsgId, 'violation', content).then(() => {
      this.handleDispatchCustomEvent(events.IM_UPDATE_MESSAGES, this.messages);
      this.handleDispatchCustomEvent(events.IM_UPDATE_LIVE_MESSAGES, this.liveMessages);
    });
  }

  // 云信错误回调
  handleErrorCallback(error) {
    console.error(error);
    KKReporter(KK_EVENTS.IM_ERROR, { message: error });
  }

  // 更新会话信息
  handleUpdateSessions(sessions) {
    this.sessions = this.nim.mergeSessions(this.sessions, sessions);
    this.sessions.sort((a, b) => b.updateTime - a.updateTime);

    return this.handleSaveLocalSessions(this.sessions);
  }

  // 保存会话信息
  handleSaveLocalSessions(sessions) {
    return this.database.then((db) => db.insertData('sessions', sessions)).then(() => this.handleDispatchCustomEvent(events.IM_UPDATE_SESSIONS, this.sessions));
  }

  // 标记消息
  handleCreateLocalFlagMessage(idClient, action, payload = {}) {
    return this.database.then((db) => db.insertData('messages', { idClient, action, updateTime: +new Date(), payload: JSON.stringify(payload) }));
  }

  // 包装 promise 返回
  createNimPromiseReturn(yunxinCallbackFn, options) {
    return new Promise((resolve, reject) => {
      yunxinCallbackFn({ ...options, done: (error, data) => (error ? reject(error) : resolve(data)) });
    });
  }

  // 切换聊天对象
  handleSwitchTargetAccount(scene, targetAccountId) {
    this.messages = [];
    this.targetAccountId = targetAccountId;

    return this.handleGetHistoryMessages(scene, targetAccountId);
  }

  // 设置当前会话
  handleSetCurrentSession(sessionId) {
    this.nim.setCurrSession(sessionId);

    // 手动清除本地会话记录
    // 手动刷新内存中的sessions
    this.database.then((db) => db.updateData('sessions', ['id', sessionId], { unread: 0 })).then(() => this.handleInitLocalSessions());
  }

  // 重置当前会话
  handleResetCurrentSession() {
    this.nim.resetCurrSession();
  }

  // 获取服务器端聊天记录
  handleGetHistoryMessages(scene, to) {
    const params = {
      limit: 100, scene, to, reverse: false, asc: true,
    };

    // 加载聊天记录分页
    if (this.messages.length > 0) {
      Object.assign(params, { endTime: this.messages[0].time, lastMsgId: this.messages[0].idServer });
    }
    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.getHistoryMsgs.bind(this.nim), params))
      .then((result) => {
        console.log('加载聊天记录分页: ', result);
        this.messages = result.msgs.concat(this.messages).filter((message) => !this.handleAskGiftMsgByServerFilter(message));
      })
      .then(() => this.handleUpdateFlagMessages())
      .then(() => this.emit(events.IM_UPDATE_MESSAGES, this.messages));
  }

  // 获取可用的实时消息
  handleGetRealMessages() {
    const idClients = this.flagMessages.filter((fMessage) => fMessage.action === 'hidden').map((fMessage) => fMessage.idClient);
    return this.messages.filter((message) => idClients.indexOf(message.idClient) === -1);
  }

  // 获取可用的实时会话
  handleGetRealSessions() {
    return this.sessions;
  }

  // 获取直播间消息
  handleGetRealLiveMessages() {
    return this.liveMessages;
  }

  // 构建客户端自定义发送消息
  handleGetDefaultMsgCustomInfo() {
    return {
      isVip: '1', qaAnswer: '', sayHi: '', isRecharged: '2', forceShow: '2', ignoreVip: '2', uaInfo: process.env.VUE_APP_AGENT, matchMsg: '1', isFilter: '',
    };
  }

  // 发送文本消息
  handleSendTextMessage(scene, to, text, custom = {}) {
    const fullCustom = { ...this.handleGetDefaultMsgCustomInfo(), ...custom };

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.sendText.bind(this.nim), { scene, to, text, custom: JSON.stringify(fullCustom) }))
      .then((result) => this.handleSendOrReceiveMessageAfterHooks(result))
      .catch((e) => {
        console.log('发送文本消息:', e);
      });
  }

  // 发送直播间消息 -> 不存储消息记录 -> 通过字段过滤
  handleSendLiveTextMessage(scene, to, text, custom = {}) {
    const customInfo = { ...this.handleGetDefaultMsgCustomInfo(), isFilter: '1', matchMsg: '1', ...custom };
    const options = {
      scene, to, text, isHistoryable: false, isPushable: false, isRoamingable: false, isSyncable: false, isUnreadable: false, needPushNick: false, needUpdateSession: false, isOfflinable: false, custom: JSON.stringify(customInfo),
    };

    console.log('发送直播间消息', customInfo, options);

    // return Promise.resolve(true);

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.sendText.bind(this.nim), options))
      .then((result) => this.handleSendOrReceiveMessageAfterHooks(result, true))
      .catch((e) => {
        console.log('发送文本消息:', e);
      });
  }

  // 发送文件消息
  handleSendFileMessage(scene, to, type, blob, custom = {}) {
    const fullCustom = { ...this.handleGetDefaultMsgCustomInfo(), ...custom };

    const uploadOptions = {
      uploaddone(error, file) {
        return !error ? Promise.resolve(file) : Promise.reject(error);
      },
    };
    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.sendFile.bind(this.nim), { scene, to, type, blob, custom: JSON.stringify(fullCustom), ...uploadOptions }))
      .then((result) => this.handleSendOrReceiveMessageAfterHooks(result));
  }

  // 发送自定义消息
  handleSendCustomMessage(scene, to, content, custom = {}) {
    const fullCustom = { ...this.handleGetDefaultMsgCustomInfo(), ...custom };

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.sendCustomMsg.bind(this.nim), { scene, to, content: JSON.stringify(content), custom: JSON.stringify(fullCustom) }))
      .then((result) => this.handleSendOrReceiveMessageAfterHooks(result));
  }

  // 发送话单消息
  handleSendG2Message(scene, to, attach, custom = {}) {
    const fullCustom = { ...this.handleGetDefaultMsgCustomInfo(), ...custom };

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.createNimPromiseReturn(this.nim.sendG2Msg.bind(this.nim), { scene, to, attach, custom: JSON.stringify(fullCustom) }))
      .then((result) => this.handleSendOrReceiveMessageAfterHooks(result));
  }
}
