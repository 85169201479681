import dayjs from 'dayjs';

import { LOGIN_TYPE } from '@/utils/consts';
import { getTouristUniqueDeviceId } from '@/providers/touristAccount';
import { handleKKReportLog, handleKKStatisticsReport } from '@/services/notice';
import { getUserInfoFromStorage, handleGetEntryScenes, getBrowserType } from '@/utils/utils';

// pixel 埋点
export const FBR_EVENTS = {
  CompleteRegistration: 'CompleteRegistration',
  Purchase: 'Purchase',
  CustomPurchase: 'custom_purchase',
  InitiateCheckout: 'InitiateCheckout',
  CustomInitiateCheckout: 'custom_initiate_checkout',
};

// firebase 埋点
export const FIREBASE_EVENTS = {
  SignUp: 'sign_up',
  BeginCheckout: 'begin_checkout',
  Purchase: 'purchase',
  CustomBeginCheckout: 'custom_begin_checkout',
  CustomPurchase: 'custom_purchase',
};

// 操作日志打点上报
export const KK_EVENTS = {
  SIGN_UP: 'SIGN_UP',
  PURCHASE: 'PURCHASE',
  INITIATE_CHECKOUT: 'INITIATE_CHECKOUT',

  IM_ERROR: 'IM_ERROR',
  IM_CONNECTED: 'IM_CONNECTED',
  IM_DISCONNECTED: 'IM_DISCONNECTED',
  IM_OFFLINE_MESSAGES: 'IM_OFFLINE_MESSAGES',
  IM_WILL_RECONNECTING: 'IM_WILL_RECONNECTING',
  AGORA_CHANNEL_STATUS: 'AGORA_CHANNEL_STATUS',

  FACEBOOK_REPORT_ERROR: 'FACEBOOK_REPORT_ERROR',
};

// 统计上报
export const KK_STATISTICS_EVENTS = {
  ENTRY_LOGINED_APPLICATION: 'ENTRY_LOGINED_APPLICATION',

  TOURISTS_SINGUP: 'TOURISTS_SINGUP',
  TOURISTS_SINGUP_OPERATE: 'TOURISTS_SINGUP_OPERATE',

  ENTRY_MODAL: 'ENTRY_MODAL',
  ENTRY_MODAL_SAVE: 'ENTRY_MODAL_SAVE',

  EXIT_MODAL: 'EXIT_MODAL',
  EXIT_MODAL_SAVE: 'EXIT_MODAL_SAVE',
  EXIT_MODAL_SEVEN_REMIND: 'EXIT_MODAL_SEVEN_REMIND',
};

function handleAppendUserInfo(payload) {
  const userInfo = getUserInfoFromStorage();
  return { ...payload, userStringId: userInfo.userStringId, time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
}

export function KKReporter(event, payload) {
  const detail = { ...handleAppendUserInfo(payload), ua: window.navigator.userAgent };

  return handleKKReportLog({ type: event, deviceId: getTouristUniqueDeviceId(), scene: 'activity', code: 0, detail: JSON.stringify(detail) });
}

export function FBReporter(event, payload, isCustom = false) {
  if (!window.fbq || !window.fbq.callMethod) {
    KKReporter(KK_EVENTS.FACEBOOK_REPORT_ERROR, { fbPayload: { event, payload, isCustom } });
    return;
  }
  window.fbq(isCustom ? 'trackCustom' : 'track', event, handleAppendUserInfo(payload));
}

export function FireBaseReporter(event, payload) {
  if (!window.gtag) {
    return;
  }
  window.gtag('event', event, handleAppendUserInfo(payload));
}

export function handleGetKKStatisticsReportParams(event) {
  const browser = getBrowserType();
  const scenes = handleGetEntryScenes();
  const browserMap = { uc: 3, safari: 1, chrome: 2 };

  if (event === KK_STATISTICS_EVENTS.TOURISTS_SINGUP) {
    // eslint-disable-next-line no-nested-ternary
    return { accountSource: scenes === 'kkfb' ? 2 : (scenes === 'kkgg' ? 3 : 1) };
  }
  if (event === KK_STATISTICS_EVENTS.TOURISTS_SINGUP_OPERATE) {
    return { operate: 0 };
  }
  if (event === KK_STATISTICS_EVENTS.ENTRY_LOGINED_APPLICATION) {
    return { operate: 1, action: scenes === 'kkapp' ? 5 : (browserMap[browser] || 4) };
  }
  if (event === KK_STATISTICS_EVENTS.ENTRY_MODAL_SAVE) {
    return { operate: 2, action: 6 };
  }
  if (event === KK_STATISTICS_EVENTS.EXIT_MODAL_SAVE) {
    return { operate: 3, action: 7 };
  }
  if (event === KK_STATISTICS_EVENTS.EXIT_MODAL_SEVEN_REMIND) {
    return { operate: 3, action: 8 };
  }
  return {};
}

export function KKStatisticsReport(event) {
  return handleKKStatisticsReport(handleGetKKStatisticsReportParams(event));
}

// 注册上报
export function handleSignInReportContainThird() {
  const loginType = window.sessionStorage.getItem(LOGIN_TYPE);

  if (!loginType) {
    return Promise.resolve(true);
  }

  FBReporter(FBR_EVENTS.CompleteRegistration, { status: true, content_name: loginType });
  FireBaseReporter(FIREBASE_EVENTS.SignUp, { status: true, content_name: loginType });

  return Promise.all([KKStatisticsReport(KK_STATISTICS_EVENTS.TOURISTS_SINGUP_OPERATE), KKReporter(KK_EVENTS.SIGN_UP, { status: true, content_name: loginType })]);
}
