import { events } from '@/wy-im/config';
import { getImHandlerInstance } from '@/wy-im/index';
import { ref, computed, onMounted } from 'vue';

export function useImInstance() {
  const imInstance = ref(getImHandlerInstance());
  const messages = ref(imInstance.value.handleGetRealMessages());
  const sessions = ref(imInstance.value.handleGetRealSessions());
  const liveMessages = ref(imInstance.value.handleGetRealLiveMessages());

  const messagesWithTimes = computed(() => {
    if (messages.value.length === 0) {
      return [];
    }

    // eslint-disable-next-line prefer-const
    let [lastTime, result] = [0, []];

    messages.value.forEach((message) => {
      if (result.length === 0 || message.time - lastTime > 1000 * 60 * 10) {
        lastTime = message.time;
        result.push({ type: 'time-label', value: message.time });
      }
      result.push(message);
    });
    return result;
  });

  imInstance.value.on(events.IM_UPDATE_MESSAGES, () => {
    messages.value = [].concat(imInstance.value.handleGetRealMessages());
  });

  imInstance.value.on(events.IM_UPDATE_SESSIONS, () => {
    sessions.value = [].concat(imInstance.value.handleGetRealSessions()).sort((a, b) => b.updateTime - a.updateTime);
  });

  imInstance.value.on(events.IM_UPDATE_LIVE_MESSAGES, () => {
    liveMessages.value = [].concat(imInstance.value.handleGetRealLiveMessages());
  });

  onMounted(() => {
    messages.value = imInstance.value.handleGetRealMessages();
    sessions.value = imInstance.value.handleGetRealSessions();
    liveMessages.value = imInstance.value.handleGetRealLiveMessages();
  });

  return {
    imInstance, messages, messagesWithTimes, sessions, liveMessages,
  };
}
