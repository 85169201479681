export default {
  标题: 'Change Password',
  保存: 'Save',
  新密码: 'New Password',
  输入密码占位文案: 'please input password',
  密码提醒: '6-16 digits password,numbers or letters',
  确认密码: 'Confirm The New Passwore',
  确认密码描述: 'The new password must be the same as the confirm password',
  密码不同: 'passwords is different',
  密码为空: 'password is empty',
  密码格式错误: 'password is invalid',
  修改成功: 'change success',
};
