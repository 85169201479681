import { Overlay } from 'vant';
import { makeI18nT } from '@/languages/index';
import { getBrowserType } from '@/utils/utils';
import { IMG_RESOURCE_DOMAIN } from '@/utils/consts';
import { defineComponent, ref, watchEffect } from 'vue';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { opecationGuideForSafari, opecationGuideForChrome } from './config';

import './opecationGuideIos.scss';

export default defineComponent({
  name: 'opecation-guide-ios',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const visible = ref(false);

    const handleOverlayClick = () => {
      visible.value = false;
    };

    watchEffect(() => {
      visible.value = props.modelValue;
    });

    watchEffect(() => emit('update:modelValue', visible.value));

    useLockBodyScroll(visible);

    const { t } = makeI18nT('cAddToHomeScreen');

    return {
      visible, handleOverlayClick, t,
    };
  },
  render() {
    return (
      <Overlay z-index="199" lazyRender={false} show={this.visible} onClick={this.handleOverlayClick}>
        {this.handleRenderGuideContent()}
      </Overlay>
    );
  },
  methods: {
    handleRenderGuideContent() {
      const browserType = getBrowserType();

      if (browserType === 'safari') {
        return this.handleRenderGuideForSafari();
      }
      if (browserType === 'chrome') {
        return this.handleRenderGuideForChrome();
      }
      return this.handleRenderGuideForFallbackOther();
    },
    handleRenderGuideForSafari() {
      return this.handleCreateGuideContent(opecationGuideForSafari);
    },
    handleRenderGuideForChrome() {
      return this.handleCreateGuideContent(opecationGuideForChrome);
    },
    handleRenderGuideForFallbackOther() {
      return this.handleCreateFallbackContent();
    },
    handleCreateGuideContent(setting) {
      return (
        <div className="ogi-container">
          <div className="ogi-container-title">
            <div>{this.t(`cAddToHomeScreen.${setting.title}`)}</div>
          </div>
          <div className="ogi-container-content">
            {setting.steps.map(this.handleCreateGuideItemContent.bind(this))}
          </div>
        </div>
      );
    },
    handleCreateGuideItemContent(item, index) {
      return (
        <div key={index} className="ogi-container-content-item">
          <div className="ogi-container-content-item-text">{this.t(`cAddToHomeScreen.${item.title}`)}</div>
          <div className="ogi-container-content-item-img">
            <img src={item.img} />
          </div>
        </div>
      );
    },
    handleCreateFallbackContent() {
      return (
        <div className="ogi-fallback-container">
          <div className="ogi-fallback-container-icon">
            <img src={`${IMG_RESOURCE_DOMAIN}/logo_icon.png`} />
          </div>
          <div className="ogi-fallback-container-title">
            <div>{this.t('cAddToHomeScreen.保存桌面图标')}</div>
            <div>{this.t('cAddToHomeScreen.方便下次使用哦')}</div>
          </div>
        </div>
      );
    },
    setVisible(visible) {
      this.visible = visible;
    },
  },
});
