export default {
  举报: 'प्रतिवेदन',
  拉黑: 'काला',
  在线: 'ऑनलाइन',
  个人信息: 'जानकारी',
  最近上线时间: 'अंतिम ऑनलाइन समय',
  恋爱观念: 'प्रेम की अवधारणा',
  交友目的: 'डेटिंग का उद्देश्य',
  职业: 'काम',
  生日: 'जन्मदिन',
  身高: 'ऊंचाई',
  体重: 'वज़न',
  相册: 'एलबम',
  视频: 'वीडियो',
  打招呼: 'नमस्ते',
  发消息: 'संदेश',
  视频通话: 'वीडियो कॉल',
  Hi: 'आपसे मिलकर अच्छा लगा',
  开通VIP可查看: 'सभी जानकारी देखने के लिए वीआईपी खोलें',
  开通Vip: 'वीआईपी खोलें',
  拉黑提示: 'काली सफलता',
  拉黑文案: 'इस उपयोगकर्ता को ब्लॉक करें? वे आपकी निजी जानकारी नहीं देख पाएंगे',
};
