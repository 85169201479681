import { events } from '@/wy-im/config';
import { onMounted, reactive, ref } from 'vue';
import { getUserInfoFromStorage } from '@/utils/utils';
import { useImInstance } from '@/hooks/useImInstance';
import { handleBatchGetUserInfosByYXIMIds } from '@/services/user';

let fakeTimer = null;
let fakeRoposeTimer = null;

export function useDispatchVoiceEventListener() {
  const status = ref('');
  const visible = ref(false);
  const targetUserInfo = reactive({});

  const { imInstance } = useImInstance();

  const userInfo = getUserInfoFromStorage();

  // 请求用户信息
  const handleGetTargetUserInfo = (imId) => handleBatchGetUserInfosByYXIMIds({ imIds: [imId], callType: 0 }).then((result) => {
    if (result.code !== 0) {
      return;
    }
    Object.assign(targetUserInfo, result.data[0]);
  });

  // 监听主动调起语音
  const handleRoposeCallListener = () => {
    const { toAccid, fromAccid } = imInstance.value.getInvitedInfos();

    handleGetTargetUserInfo(fromAccid === userInfo.imId ? toAccid : fromAccid).then(() => {
      status.value = 'initiate';
      visible.value = true;
    });
  };

  // 取消假的通话
  const handleCancelFakeRoposeCall = () => {
    if (fakeRoposeTimer) {
      window.clearTimeout(fakeRoposeTimer);
      fakeRoposeTimer = null;
    }

    status.value = '';
    visible.value = false;

    imInstance.value.handleResetInvitedInfos();
  };

  // 监听调起假的通话
  const handleFakeRoposeCallListener = (invitedInfos) => {
    const { toAccid, fromAccid } = invitedInfos;

    handleGetTargetUserInfo(fromAccid === userInfo.imId ? toAccid : fromAccid).then(() => {
      status.value = 'initiate';
      visible.value = true;
    });

    fakeRoposeTimer = window.setTimeout(() => handleCancelFakeRoposeCall(), 30 * 1000);
  };

  // 取消假的邀请
  const handleEndFakeReciveInvite = () => {
    if (fakeTimer) {
      window.clearTimeout(fakeTimer);
      fakeTimer = null;
    }
    status.value = '';
    visible.value = false;

    imInstance.value.handleResetInvitedInfos();
  };

  // 假的接收到邀请
  const handleFakeReciveInvitedListener = (toAccid) => {
    handleGetTargetUserInfo(toAccid).then(() => {
      status.value = 'invited';
      visible.value = true;

      imInstance.value.handleSetFakeInvitedInfos();

      fakeTimer = window.setTimeout(() => handleEndFakeReciveInvite(), 30 * 1000);
    });
  };

  // 监听接收到邀请
  const handleReciveInvitedListener = () => {
    const { toAccid, fromAccid } = imInstance.value.getInvitedInfos();

    handleGetTargetUserInfo(fromAccid === userInfo.imId ? toAccid : fromAccid).then(() => {
      status.value = 'invited';
      visible.value = true;
    });
  };

  // 监听双方进入 rtc 频道
  const handleEntryCallListener = () => {
    status.value = 'calling';
    visible.value = true;
  };

  // 监听主动拒绝邀请
  const handleEndCallListener = () => {
    status.value = '';
    visible.value = false;
  };

  // 派发事件监听
  const handleDispatchEventListener = () => {
    // 主动调起语音
    imInstance.value.on(events.XL_PROPOSE_VOICE_CALL, () => handleRoposeCallListener());

    // 调起假的通话
    imInstance.value.on(events.XL_FAKE_PROPOSE_VOICE_CALL, (invitedInfos) => handleFakeRoposeCallListener(invitedInfos));

    // 接收到邀请
    imInstance.value.on(events.XL_RECIVE_VOICE_INVITED, () => handleReciveInvitedListener());

    // 假的接收到邀请
    imInstance.value.on(events.XL_FAKE_RECIVE_VOICE_INVITED, (imId) => handleFakeReciveInvitedListener(imId));

    // 双方进入 rtc 频道
    imInstance.value.on(events.XL_ENTRY_VOICE_CALL, () => handleEntryCallListener());

    // 主动拒绝邀请
    imInstance.value.on(events.XL_END_VOICE_CALL, () => handleEndCallListener());
  };

  onMounted(() => handleDispatchEventListener());

  return {
    imInstance, targetUserInfo, visible, status, handleEndFakeReciveInvite, handleCancelFakeRoposeCall,
  };
}
