export default {
  取消: 'रद्द करना',
  确认: 'पुष्टि करना',
  VIP挽留弹窗内容: 'खूबसूरती अब भी आपका इंतजार कर रही है क्या आप वाकई अपना वीआईपी स्टेटस छोड़ना चाहते हैं',
  VIP挽留弹窗取消: 'बेरहमी से चूक गए',
  VIP挽留弹窗确定: 'फिर से विचार करना',
  钻石挽留弹窗内容一行: 'सुंदरता अभी भी आपके साथ हीरों के साथ बातचीत करने का इंतजार कर रही है',
  钻石挽留弹窗内容二行: 'क्या आप निश्चित रूप से हीरे की टॉप अप नहीं करेंगे?',
  钻石挽留弹窗取消: 'चुक होना',
  钻石挽留弹窗确定: 'के बारे में सोचो',
  HI弹窗内容: 'आप पहले ही 5 निःशुल्क अभिवादन अवसरों का उपयोग कर चुके हैं, वीआईपी खरीदें और स्वतंत्र रूप से चैट करें',
  HI弹窗取消: 'रद्द करना',
  HI弹窗确定: 'अभी खरीदें',
};
