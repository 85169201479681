export const AUTHORIZATION = 'AUTHORIZATION';
export const TOURIST_DEVICE_ID = 'TOURIST_DEVICE_ID';
export const IMG_RESOURCE_DOMAIN = 'https://cdn-file.woolookme.com/BlissLiveWeb';
export const IMG_STATIC_DOMAIN = '/img/static';
export const IM_AUTHORIZATION = 'IM_AUTHORIZATION';
export const USER_INFO = 'USER_INFO';
export const SAVE_TO_HOMR_OK = 'SAVE_TO_HOMR_OK';
export const IS_SHOW_SAVE_TO_HOMR = 'IS_SHOW_SAVE_TO_HOMR';
export const FOCUS_REFRESH_HOT = 'FOCUS_REFRESH_HOT';
export const FLAG_HOT_HI = 'FLAG_HOT_HI';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const AD_PLATFORM = 'AD_PLATFORM';
export const AD_PLATFORM_REFFER = 'AD_PLATFORM_REFFER';
export const ADVERTISE_SOURCE = ['kkgg', 'kkfb'];
export const THIRD_DATA_COMPLETED = 'THIRD_DATA_COMPLETED';
export const GCL_ID = 'GCL_ID';
