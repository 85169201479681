import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'target-user-avatar',
  props: ['targetUserInfo'],
  setup(props) {
    const address = computed(() => props.targetUserInfo.city || props.targetUserInfo.country || '');
    return {
      address
    };
  }
});