import { defineComponent } from 'vue';
import { useImInstance } from '@/hooks/useImInstance';
import { GlobalRTCAudio, GlobalRTCVideo } from '@/components/av-rtc-group/index';
import ViewWrap from '@/components/view-wraper/index.vue';
import 'vant/es/dialog/style';
import 'vant/es/toast/style/index';
import 'vant/es/uploader/style/index';
export default defineComponent({
  name: 'App',
  components: {
    'view-wrap': ViewWrap,
    'global-rtc-audio': GlobalRTCAudio,
    'global-rtc-video': GlobalRTCVideo
  },
  setup() {
    const {
      imInstance
    } = useImInstance();
    return {
      imInstance
    };
  },
  mounted() {
    this.imInstance.RtcAudioDOM = this.$refs.RtcAudioDOM;
    this.imInstance.RtcVideoDOM = this.$refs.RtcVideoDOM;
  }
});