export default {
  保存桌面图标: 'डेस्कटॉप आइकन सहेजें',
  方便下次使用哦: 'यह अगली बार के लिए सुविधाजनक है',
  '第一步点击下方【分享】': 'पहला कदम नीचे 【शेयर】 पर क्लिक करना है',
  '第一步点击右上角【更多】': 'पहला कदम ऊपरी दाएं कोने में 【अधिक】 पर क्लिक करना है',
  '第二步点击【添加到主屏幕】': 'चरण 2 【होम स्क्रीन पर जोड़ें】 पर क्लिक करें',
  '第三步点击【添加】': 'चरण 3 【जोड़ें】 पर क्लिक करें',

  保存到桌面来获得更佳的体验: 'बेहतर अनुभव के लिए डेस्कटॉप पर सहेजें',
  取消: 'रद्द करना',
  确定: 'ज़रूर',

  保存图标: 'आइकन सहेजें',

  '7天内不再提醒': '7 दिनों के भीतर कोई और अनुस्मारक नहीं',
  正在尝试创建桌面图标: 'डेस्कटॉप आइकन बनाने का प्रयास किया जा रहा है',
};
