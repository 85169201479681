// 应用初始化
import { initIMIndexdb } from '@/database/index';
import { handleOnlineUpdateState } from '@/services/social';
import { KKStatisticsReport, KK_STATISTICS_EVENTS } from '@/providers/reporter';
import { IM_AUTHORIZATION, AUTHORIZATION, SAVE_TO_HOMR_OK, AD_PLATFORM_REFFER } from '@/utils/consts';
import { getUserInfoFromStorage, getPlatformType, isMobile, handleForbidMobileScaleEvents, isInFacebook, handleGetQuery } from '@/utils/utils';
import { handleTriggerAddToHomeDialogOnQuitByAndroid, handleTriggerAddToHomeDialogOnQuitByIOS, handleTriggerGuideDialogByFacebook } from '@/components/add-to-home-screen/index';

import * as yx from '@/wy-im/index';

// 保存桌面句柄
window.deferredPrompt = null;

// 强制显示离开弹窗提示
window.focusBeforeLoadTip = false;

export default class Bootstrapt {
  static instance = null;

  constructor() {
    this.handleTriggerGuideDialogByFacebookFunc = handleTriggerGuideDialogByFacebook();
    this.handleTriggerAddToHomeDialogOnQuitByIOSFunc = handleTriggerAddToHomeDialogOnQuitByIOS();
    this.handleTriggerAddToHomeDialogOnQuitByAndroidFunc = handleTriggerAddToHomeDialogOnQuitByAndroid();
  }

  init() {
    // eslint-disable-next-line newline-per-chained-call
    Bootstrapt.instance = this.handleRecordScenesReffer().initBrowserActivity().initAddToHomeEventListener().handleTryAutoClose();
    return !window.localStorage.getItem(AUTHORIZATION) ? Promise.resolve(this) : this.initHeartbeat().handleEntryReport().initIMDatabase().initIM();
  }

  // 初始化行为
  initBrowserActivity() {
    return handleForbidMobileScaleEvents() || this;
  }

  // 保存至主屏幕事件初始化
  initAddToHomeEventListener() {
    // pwa 安装
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      window.deferredPrompt = event;

      console.log('pwa beforeinstallprompt: ', event);
    });

    // load 页面
    window.addEventListener('load', () => this.handleTriggerSaveOrFacebookGuideDialog(false));

    // 页面离开
    window.addEventListener('beforeunload', this.handleBeforeUnloadListener.bind(this));

    // 页面可见
    document.addEventListener('visibilitychange', () => document.visibilityState === 'visible' && isMobile() && this.handleTriggerSaveOrFacebookGuideDialog(true));

    return this;
  }

  // 页面离开 -> 基本不会执行到
  handleBeforeUnloadListener(event) {
    // 网站内部跳转 -> 不显示弹窗 - 强制跳转第三方网站 -> 打开强制跳转
    if (!window.focusBeforeLoadTip) {
      return;
    }

    event.preventDefault();

    // eslint-disable-next-line no-param-reassign
    event.returnValue = '添加到主屏幕';

    this.handleTriggerSaveOrFacebookGuideDialog(true);

    // eslint-disable-next-line consistent-return
    return '添加到主屏幕';
  }

  // 触发弹窗
  handleTriggerSaveOrFacebookGuideDialog(visible) {
    const isSaved = window.localStorage.getItem(SAVE_TO_HOMR_OK) === '1';

    if (isInFacebook()) {
      this.handleTriggerGuideDialogByFacebookFunc(visible);
      return;
    }

    if (getPlatformType() === 'android' && !isSaved) {
      this.handleTriggerAddToHomeDialogOnQuitByAndroidFunc(visible);
    }

    if (getPlatformType() === 'ios' && !isSaved) {
      this.handleTriggerAddToHomeDialogOnQuitByIOSFunc(visible);
    }
  }

  // IM 本地数据库初始化
  initIMDatabase() {
    return initIMIndexdb() || this;
  }

  // IM 模块初始化
  initIM() {
    const userInfo = getUserInfoFromStorage();
    const IMToken = window.localStorage.getItem(IM_AUTHORIZATION) || '';

    const options = {
      token: IMToken,
      account: userInfo.imId,
      lbsUrl: process.env.VUE_APP_YX_LBS,
      appKey: process.env.VUE_APP_YX_APPKEY,
      defaultLink: process.env.VUE_APP_YX_LINK,
    };

    yx.init(options);

    return Promise.resolve(this);
  }

  // 心跳初始化
  initHeartbeat() {
    const heartbeatRequest = () => handleOnlineUpdateState({}).then((result) => {
      if (result.code !== 0) {
        return;
      }
      console.log('发送心跳');
    });

    heartbeatRequest();
    setInterval(() => heartbeatRequest(), 1000 * 60);

    return this;
  }

  // 尝试自动关闭
  handleTryAutoClose() {
    if (window.opener) {
      window.close();
    }
    return this;
  }

  // 记录跳转浏览器来源
  handleRecordScenesReffer() {
    if (!window.sessionStorage.getItem(AD_PLATFORM_REFFER)) {
      window.sessionStorage.setItem(AD_PLATFORM_REFFER, handleGetQuery('scenes') || '');
    }
    return this;
  }

  // 进入应用上报
  handleEntryReport() {
    KKStatisticsReport(KK_STATISTICS_EVENTS.ENTRY_LOGINED_APPLICATION);
    return this;
  }
}
