import { routes } from './routes';
import { GCL_ID } from '@/utils/consts';
import { handleGetEntryScenes } from '@/utils/utils';
import { createRouter, createWebHistory } from 'vue-router';
import { leaveVipFilter, leaveDiamondFilter, audioAndVideoFilter, leaveHotFilter } from './guards';

function makeWebRouterHistory() {
  const [block, webHistory] = [[], createWebHistory()];

  webHistory.listen((_, from, state) => {
    if (/\/hot/.test(from) && state.direction === 'back') {
      block.push(true);
    }
    if ((/\/mine/.test(from) || /\/messages/.test(from)) && state.direction === 'back') {
      webHistory.replace('/hot');
    }
  });

  return {
    block, webHistory,
  };
}

// eslint-disable-next-line consistent-return
async function makeVueRouterFilters(from, to, block, router, next) {
  const scenes = handleGetEntryScenes();
  const gclId = window.sessionStorage.getItem(GCL_ID);

  const toNext = () => {
    const appendedQuery = { ...to.query };

    if (!to.query.gclid && gclId) {
      appendedQuery.gclid = gclId;
    }

    if (!to.query.scenes) {
      appendedQuery.scenes = scenes;
    }

    if ((to.query.scenes && to.query.gclid) || (to.query.scenes && !to.query.gclid && !gclId)) {
      next();
    } else {
      next({ path: to.path, query: appendedQuery });
    }
  };

  const filters = [
    { condition: () => true, handler: () => audioAndVideoFilter(), isReturn: false },
    { condition: (f, _, b) => f.path === '/hot' && b.pop(), handler: () => leaveHotFilter(), isReturn: true },
    { condition: (f) => f.path === '/member', handler: (r) => leaveVipFilter(r), isReturn: true },
    { condition: (f) => f.path === '/diamonds', handler: (r) => leaveDiamondFilter(r), isReturn: true },
  ];

  for (let i = 0, len = filters.length; i < len; i++) {
    if (!filters[i].condition(from, to, block)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line no-await-in-loop
    const result = await filters[i].handler(router);

    if (filters[i].isReturn) {
      return result ? toNext() : next(false);
    }
  }
  toNext();
}

const { block, webHistory } = makeWebRouterHistory();

export const router = createRouter({ routes, history: webHistory });

router.beforeEach((to, from, next) => makeVueRouterFilters(from, to, block, router, next));
