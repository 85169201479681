import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1aca5fd8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "chat-record-item-wrap"
};
const _hoisted_2 = {
  class: "chat-record-item-raw"
};
const _hoisted_3 = {
  key: 0,
  class: "chat-record-item-transtion"
};
const _hoisted_4 = {
  key: 0,
  class: "chat-record-item-violation"
};
const _hoisted_5 = {
  class: "chat-record-item-violation-text"
};
const _hoisted_6 = {
  key: 1,
  class: "chat-record-item-transtion-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_iconfont = _resolveComponent("svg-iconfont");
  const _component_van_loading = _resolveComponent("van-loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['chat-record-item', _ctx.violationText ? '' : 'radius'])
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
    class: _normalizeClass(_ctx.data.from !== _ctx.userInfo.imId ? 'focus' : '')
  }, _toDisplayString(_ctx.data.from === _ctx.userInfo.imId ? _ctx.userInfo.nickname : _ctx.targetUserInfo.nickname), 3), _createElementVNode("span", null, ": " + _toDisplayString(_ctx.data.text), 1)]), _ctx.translateText ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.translateText), 1)])) : _createCommentVNode("", true)], 2), _ctx.violationText ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_svg_iconfont, {
    class: "chat-record-item-violation-icon",
    "icon-name": "warn-tip"
  }), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.violationText), 1)])) : _createCommentVNode("", true), _ctx.data.from !== _ctx.userInfo.imId ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_ctx.loading ? (_openBlock(), _createBlock(_component_van_loading, {
    key: 0,
    type: "spinner",
    class: "translate-loading",
    size: "14",
    color: "#ffffff"
  })) : (_openBlock(), _createBlock(_component_svg_iconfont, {
    key: 1,
    class: "translate-icon",
    "icon-name": "translate",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleTranslateText(_ctx.data))
  }))])) : _createCommentVNode("", true)]);
}