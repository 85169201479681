import { useI18n } from 'vue-i18n';
import { getSystemLanguage } from '@/utils/utils';

export function useI18nLanguage(languageMessages) {
  const { t } = useI18n({ locale: getSystemLanguage(), messages: languageMessages });

  return {
    t,
  };
}
