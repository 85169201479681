export default {
  一键注册: '一键注册',
  注册同意: '平台注册意味着您已经阅读并同意',
  用户协议: '用户协议',
  和: '和',
  隐私协议: '隐私协议',
  google: 'google',
  facebook: 'facebook',
  account: 'account',
};
