export default {
  标题: 'Report',
  选择问题类型: 'Select question type',
  上传证明: 'Upload certificate',
  输入框占位: 'please describe the problem you are experiencing',
  继续按钮: 'Continue',
  Erotic: 'Erotic',
  Advertising: 'Advertising',
  Other: 'Other',
  'Violence/Verbal Assult': 'Violence/Verbal Assult',
  举报成功: 'successful report',
  请添加描述: 'Please input your description',
};
