export default {
  标题: '充值 Vip',
  解锁聊天: '解锁聊天限制',
  解锁聊天描述: '与任何人无限聊天',
  解锁视频: '访问通话',
  解锁视频描述: '通话购买钻石',
  提升质量: '优质用户推荐',
  提升质量描述: '推荐你更好更热情的妹子',
  展示Logo: 'VIP专属标识',
  展示Logo描述: '让更多有趣的人找到你',
  个人信息: '解锁所有个人信息',
  解锁个人信息描述: '可以查看秘密相册和视频',
  会员特权: '会员特权',
};
