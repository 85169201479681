import { events } from '@/wy-im/config';
import { defineComponent, ref, computed } from 'vue';
import { useImInstance } from '@/hooks/useImInstance';
import { handleTryParseJSON } from '@/utils/utils';
import ChatWindowRecordByVideo from './ChatWindowRecordByVideo.vue';
export default defineComponent({
  name: 'chat-window-by-video',
  props: ['targetUserInfo'],
  components: {
    'chat-window-record-by-video': ChatWindowRecordByVideo
  },
  setup() {
    const {
      liveMessages,
      imInstance
    } = useImInstance();
    const violationMsgs = ref([]);
    const lastMsgItem = computed(() => liveMessages.value[liveMessages.value.length - 1]);
    return {
      liveMessages,
      lastMsgItem,
      violationMsgs,
      imInstance
    };
  },
  watch: {
    'lastMsgItem.idClient': {
      handler() {
        this.$nextTick(() => this.handleTriggerScrollAnimation()());
      }
    }
  },
  computed: {
    getViolationText() {
      return msg => {
        var _handleTryParseJSON$d;
        const current = this.violationMsgs.find(item => item.idClient === msg.idClient);
        return current ? (_handleTryParseJSON$d = handleTryParseJSON(current.payload).data) === null || _handleTryParseJSON$d === void 0 ? void 0 : _handleTryParseJSON$d.hintStr : '';
      };
    }
  },
  mounted() {
    this.handleUpdateViolationMsgs();
  },
  methods: {
    handleUpdateViolationMsgs() {
      this.imInstance.on(events.IM_UPDATE_LIVE_MESSAGES, () => {
        this.imInstance.handleGetLocalFlagMessage('violation').then(result => {
          this.violationMsgs = result;
          this.liveMessages = [].concat(this.liveMessages);
        });
      });
    },
    handleTriggerScrollAnimation() {
      const {
        liveChatWindow
      } = this.$refs;
      const {
        height
      } = liveChatWindow.getBoundingClientRect();
      let handler = null;
      return () => {
        cancelAnimationFrame(handler);
        const animation = () => {
          liveChatWindow.scrollTop += liveChatWindow.scrollTop < liveChatWindow.scrollHeight ? 50 : 0;
          if (Math.round(liveChatWindow.scrollTop + height) < liveChatWindow.scrollHeight - 5) {
            handler = requestAnimationFrame(animation);
          } else {
            cancelAnimationFrame(handler);
          }
        };
        requestAnimationFrame(animation);
      };
    }
  }
});