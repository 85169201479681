export default {
  保存桌面图标: '保存桌面图标',
  方便下次使用哦: '方便下次使用哦',
  '第一步点击下方【分享】': '第一步点击下方【分享】',
  '第一步点击右上角【更多】': '第一步点击右上角【更多】',
  '第二步点击【添加到主屏幕】': '第二步点击【添加到主屏幕】',
  '第三步点击【添加】': '第三步点击【添加】',

  保存到桌面来获得更佳的体验: '保存到桌面来获得更佳的体验',
  取消: '取消',
  确定: '确定',

  保存图标: '保存桌面图标',

  '7天内不再提醒': '7天内不再提醒',
  正在尝试创建桌面图标: '正在尝试创建桌面图标',
};
