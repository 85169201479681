import { ref, onMounted, onActivated } from 'vue';
import { handleGetIsVip } from '@/services/vip';
import { AUTHORIZATION } from '@/utils/consts';

export function useIsVip() {
  const isVip = ref(false);

  const handleFetchIsVip = () => {
    const token = window.localStorage.getItem(AUTHORIZATION) || '';

    if (!token) {
      return;
    }
    handleGetIsVip().then((result) => {
      isVip.value = result.code === 0 ? result.data.isMember : false;
    });
  };

  onMounted(() => handleFetchIsVip());

  onActivated(() => handleFetchIsVip());

  return {
    isVip,
  };
}
