import { defineComponent, ref, watchEffect } from 'vue';
import Iconfont from '@/components/iconfont/index.vue';
export default defineComponent({
  name: 'scale-view',
  props: ['modelValue', 'handleEleId'],
  emits: ['update:modelValue', 'handleClick'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const isSmallWindow = ref(false);
    watchEffect(() => {
      isSmallWindow.value = props.modelValue;
    });
    watchEffect(() => {
      emit('update:modelValue', isSmallWindow.value);
    });
    return {
      isSmallWindow
    };
  },
  components: {
    'icon-font': Iconfont
  },
  methods: {
    handleMockClickOnDragStart(event) {
      this.consumeWillClick = true;
      setTimeout(() => {
        if (!this.consumeWillClick) {
          return;
        }
        this.$emit('handleClick', event);
      }, 500);
    },
    handleMockClickOnDragMoving() {
      this.consumeWillClick = false;
    },
    handleFixedDragPosition(event) {
      const windowWidth = window.screen.width;
      const windowHeight = window.screen.height;
      const targetElement = event.target || event.srcElement;
      const {
        width,
        height
      } = targetElement.getBoundingClientRect();
      targetElement.style.left = `${Math.min(Math.max(0, parseInt(targetElement.style.left, 10)), windowWidth - width)}px`;
      targetElement.style.top = `${Math.min(Math.max(0, parseInt(targetElement.style.top, 10)), windowHeight - height)}px`;
    }
  }
});