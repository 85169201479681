// 礼物相关
import network from '@/utils/network';

// 获取礼物列表
export function handleGetGifts() {
  return network.post('/point/get-gifts');
}

// 发送礼物
export function sendGift(params) {
  return network.post('/point/send-gift', params);
}
