import { handleGetIsVip } from '@/services/vip';
import { getImHandlerInstance } from '@/wy-im/index';
import { handleGetStoneNumber } from '@/services/diamonds';
import { handleTriggerLeaveVipDialog, handleTriggerLeaveDiamondDialog } from '@/components/custom-dialog/index';

import Bootstrapt from '@/providers/bootstrap';

const guardCycleStack = [];

// 会员挽留弹窗
export function leaveVipFilter(router) {
  if (document.getElementById('leave-vip-dialog') || guardCycleStack.requestLoading) {
    return Promise.resolve(false);
  }

  if (guardCycleStack.length > 0) {
    return Promise.resolve(guardCycleStack.pop() || true);
  }

  guardCycleStack.requestLoading = true;

  return handleGetIsVip().then((result) => {
    guardCycleStack.requestLoading = false;

    const isVip = result.code === 0 ? result.data.isMember : false;

    if (!isVip) {
      handleTriggerLeaveVipDialog({}, 'leave-vip-dialog').then(() => false).catch(() => {
        guardCycleStack.push(true);
        router.back();
      });
    }
    return isVip;
  });
}

export function leaveDiamondFilter(router) {
  if (document.getElementById('leave-diamond-dialog') || guardCycleStack.requestLoading) {
    return Promise.resolve(false);
  }

  if (guardCycleStack.length > 0) {
    return Promise.resolve(guardCycleStack.pop() || true);
  }

  guardCycleStack.requestLoading = true;

  return handleGetStoneNumber().then((result) => {
    guardCycleStack.requestLoading = false;

    const count = result.code === 0 ? result.data.stone : 0;

    if (count <= 100) {
      handleTriggerLeaveDiamondDialog({}, 'leave-diamond-dialog').then(() => false).catch(() => {
        guardCycleStack.push(true);
        router.back();
      });
    }
    return count > 100;
  });
}

// 通话中跳转 -> 最小化
export function audioAndVideoFilter() {
  const imInstance = getImHandlerInstance();

  // 通话中跳转 -> 最小化
  if (imInstance.getInvitedInfos().channelInfo?.channelId) {
    imInstance.RtcAudioDOM?.handleSmallView();
    imInstance.RtcVideoDOM?.handleSmallView();
  }
}

// hot 页面离开提示弹窗
export function leaveHotFilter() {
  return !Bootstrapt.instance ? Promise.resolve(true) : Promise.resolve(!!Bootstrapt.instance.handleTriggerSaveOrFacebookGuideDialog(true));
}
