export default {
  一键注册: 'एक-क्लिक पंजीकरण',
  注册同意: 'पंजीकरण का मतलब है कि आपने प्लेटफ़ॉर्म को पढ़ लिया है और इससे सहमत हैं',
  用户协议: 'उपयोगकर्ता का समझौता',
  和: 'और',
  隐私协议: 'गोपनीयता नीति',
  google: 'गूगल',
  facebook: 'फेसबुक',
  account: 'खाता',
};
