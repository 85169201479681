import { Overlay } from 'vant';
import { makeI18nT } from '@/languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { KKStatisticsReport, KK_STATISTICS_EVENTS } from '@/providers/reporter';
import { IMG_RESOURCE_DOMAIN, SAVE_TO_HOMR_OK, IS_SHOW_SAVE_TO_HOMR } from '@/utils/consts';

import Cookies from 'js-cookie';

import './quitTipAndroid.scss';

export default defineComponent({
  name: 'quit-tip-android',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const visible = ref(false);
    const checked = ref(false);

    const handleOverlayClick = () => {
      visible.value = false;
    };

    const handleCheckedChange = () => {
      checked.value = !checked.value;
    };

    watchEffect(() => {
      visible.value = props.modelValue;
    });

    watchEffect(() => emit('update:modelValue', visible.value));

    useLockBodyScroll(visible);

    const { t } = makeI18nT('cAddToHomeScreen');

    return {
      visible, handleOverlayClick, checked, handleCheckedChange, t,
    };
  },
  render() {
    return (
      <Overlay z-index="199" lazyRender={false} show={this.visible} onClick={this.handleOverlayClick}>
        {this.handleRenderModalContent()}
      </Overlay>
    );
  },
  methods: {
    handleRenderModalContent() {
      return (
        <div className="quit-tip-android" onClick={(event) => event.stopPropagation()}>
          <div className="qta-container-icon">
            <img src={`${IMG_RESOURCE_DOMAIN}/logo_icon.png`} />
          </div>
          <div className="qta-container-title">“bliss live” {this.t('cAddToHomeScreen.正在尝试创建桌面图标')}</div>
          <div className={`qta-container-checkbox ${this.checked ? 'selected' : ''}`} onClick={this.handleCheckedChange}>{this.t('cAddToHomeScreen.7天内不再提醒')}</div>
          <div className="qta-content-btns">
            <div className="qta-content-btn-item" onClick={this.handleCancel}>{this.t('cAddToHomeScreen.取消')}</div>
            <div className="qta-content-btn-item ok" onClick={this.handleOk}>{this.t('cAddToHomeScreen.确定')}</div>
          </div>
        </div>
      );
    },
    handleCancel() {
      this.handleUpdateTipStatus();

      this.visible = false;
    },
    handleOk() {
      KKStatisticsReport(KK_STATISTICS_EVENTS.EXIT_MODAL_SAVE);

      this.handleUpdateTipStatus();

      if (!window.deferredPrompt) {
        this.visible = false;
        return;
      }

      window.deferredPrompt.prompt();

      window.deferredPrompt.userChoice.then((result) => {
        if (result.outcome !== 'accepted') {
          return;
        }
        // 已保存至主屏幕标识
        window.localStorage.setItem(SAVE_TO_HOMR_OK, '1');
      });
      this.visible = false;
    },
    handleUpdateTipStatus() {
      if (!this.checked) {
        return;
      }
      KKStatisticsReport(KK_STATISTICS_EVENTS.EXIT_MODAL_SEVEN_REMIND);

      Cookies.set(IS_SHOW_SAVE_TO_HOMR, '1', { expires: 7 });
    },
    setVisible(visible) {
      this.visible = visible;
    },
  },
});
