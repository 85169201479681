export default {
  标题: '我的',
  消息阅读: '新消息，点击阅读!',
  会员: '会员',
  钻石: '钻石',
  访客: '访客',
  设置: '设置',
  会员截止: '会员资格截止至 {time}',
  修改头像提示: '修改头像成功',
};
