import { defineComponent } from 'vue';
import { useI18nLanguage } from '@/hooks/useI18nLanguage';
import { scanfLanguageMessages } from '@/languages/index';
export default defineComponent({
  name: 'gift-footer',
  props: ['stoneNumber'],
  setup() {
    const {
      t
    } = useI18nLanguage(scanfLanguageMessages(['cGiftPopup']));
    return {
      t
    };
  },
  methods: {
    handleLinkTo(url) {
      this.$router.push(url);
    },
    handleSendGift() {
      this.$emit('onSendGift');
    }
  }
});