import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7bebd74"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-footer"
};
const _hoisted_2 = {
  class: "gift-footer-total"
};
const _hoisted_3 = {
  class: "gift-footer-total-diamond"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "gift-footer-total-diamond-number"
};
const _hoisted_6 = {
  class: "gift-footer-total-gift"
};
const _hoisted_7 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: require('@/images/mine/mine_diamond.png'),
    alt: ""
  }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.stoneNumber), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
    src: require('@/images/anchor/anchor_small_gift.png'),
    alt: ""
  }, null, 8, _hoisted_7), _createElementVNode("div", {
    class: "gift-footer-total-diamond-text",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleLinkTo('/diamonds'))
  }, [_createElementVNode("span", null, _toDisplayString(_ctx.t('cGiftPopup.充值钻石')), 1), _createVNode(_component_van_icon, {
    name: "arrow",
    color: "#FCDD0E"
  })])])]), _createElementVNode("div", {
    class: "gift-footer-send",
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.handleSendGift && _ctx.handleSendGift(...arguments);
    })
  }, _toDisplayString(_ctx.t('cGiftPopup.发送按钮')), 1)]);
}