import { defineComponent, ref } from 'vue';
import { makeI18nT } from '@/languages/index';
import { useImInstance } from '@/hooks/useImInstance';
import Iconfont from '@/components/iconfont/index.vue';
import CallOpecationByVideoClose from './CallOpecationByVideoClose.vue';
import EmojiPicker from 'vue3-emoji-picker';

// eslint-disable-next-line import/extensions
import 'vue3-emoji-picker/css';
const toolIcons = ['message', 'emoj', 'microphone', 'camera'];
export default defineComponent({
  name: 'tool-bar-by-video',
  props: ['targetUserInfo'],
  components: {
    'icon-font': Iconfont,
    'emoji-picker': EmojiPicker,
    'call-opecation-by-video-close': CallOpecationByVideoClose
  },
  setup() {
    const inputText = ref('');
    const isEmojExpand = ref(false);
    const isInputExpand = ref(false);
    const isCloseMicrophone = ref(false);
    const isInvertCamera = ref(false);
    const {
      t
    } = makeI18nT('chat');
    const {
      imInstance
    } = useImInstance();
    return {
      toolIcons,
      inputText,
      isEmojExpand,
      isInputExpand,
      isCloseMicrophone,
      imInstance,
      isInvertCamera,
      t
    };
  },
  methods: {
    handleSendMessage() {
      this.imInstance.handleSendLiveTextMessage('p2p', this.targetUserInfo.imId, this.inputText).then(() => {
        this.inputText = '';
      });
    },
    handleSelectEmoji(emoji) {
      this.inputText += emoji.i;
      this.handleSendMessage();
    },
    handleTriggerToolItem(item) {
      if (item === 'message') {
        this.isEmojExpand = false;
        this.isInputExpand = true;
      }
      if (item === 'emoj') {
        this.isEmojExpand = !this.isEmojExpand;
      }
      if (item === 'microphone') {
        this.isCloseMicrophone = !this.isCloseMicrophone;
        this.imInstance.agoraInstance.handleSetLocalVoice(this.isCloseMicrophone ? 0 : 100);
      }
      if (item === 'camera') {
        this.imInstance.agoraInstance.handleInvertCamera();
        this.isInvertCamera = !this.isInvertCamera;
      }
    },
    handleHangupCall() {
      this.$emit('onHangupCall');
    }
  }
});