export default {
  标题: 'जानकारी संपादित करें',
  保存: 'बचाना',
  昵称: 'नाम',
  性别: 'लिंग',
  关于你: 'आपके बारे में',
  信息: 'जानकारी',
  身高: 'ऊंचाई',
  体重: 'वज़न',
  性别男: 'नर',
  性别女: 'फ़ेमेल',
  确定: 'पुष्टि करना',
  取消: 'रद्द करना',
  请输入: 'कृपया यहां दर्ज करें...',
  修改成功提示: 'सफलता बदलें',
};
