export default {
  标题: 'Get Vip',
  解锁聊天: 'Unlock chat restrictions',
  解锁聊天描述: 'Unlimited chatting with anybody',
  解锁视频: 'Access to calls',
  解锁视频描述: 'Purchase diamonds for calls',
  提升质量: 'Quality user recommendation',
  提升质量描述: 'Recommend you better and more enthusiastic girl',
  展示Logo: 'VIP Exclusive Logo',
  展示Logo描述: 'Let more interesting people find you',
  个人信息: 'Unlock all personal information',
  解锁个人信息描述: 'Can view secret albums and videos',
  会员特权: 'member priviileges',
};
