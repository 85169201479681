import { showToast } from 'vant';
import { defineComponent, ref } from 'vue';
import { useGifts } from './hooks/useGifts';
import { useIsVip } from '@/hooks/useIsVip';
import { useDiamonds } from '@/hooks/useDiamonds';
import { sendGift } from '@/services/gift';
import { useI18nLanguage } from '@/hooks/useI18nLanguage';
import { scanfLanguageMessages } from '@/languages/index';
import { useImInstance } from '@/hooks/useImInstance';
import { custemMessageType } from '@/views/chat/config/index';
import { customShowConfirmDialog } from '@/components/custom-dialog/index';
import GiftSwiper from './components/GiftSwiper.vue';
import GiftFooter from './components/GiftFooter.vue';
export default defineComponent({
  name: 'gift-popup',
  components: {
    'gift-footer': GiftFooter,
    'gift-swipter': GiftSwiper
  },
  props: ['targetUserInfo'],
  setup() {
    const visible = ref(false);
    const loading = ref(false);
    const {
      isVip
    } = useIsVip();
    const {
      imInstance
    } = useImInstance();
    const {
      stoneNumber,
      handleFetchStoneNumber
    } = useDiamonds();
    const {
      t
    } = useI18nLanguage(scanfLanguageMessages(['cGiftPopup', 'cCustomDialog']));

    // eslint-disable-next-line object-curly-newline
    const {
      gifts,
      selectedGift,
      giftGroups,
      handleUpdateSelectGift
    } = useGifts();
    return {
      visible,
      loading,
      isVip,
      imInstance,
      stoneNumber,
      gifts,
      selectedGift,
      giftGroups,
      handleUpdateSelectGift,
      handleFetchStoneNumber,
      t
    };
  },
  watch: {
    visible(newValue) {
      return newValue && this.handleFetchStoneNumber();
    }
  },
  methods: {
    handleSelect(data) {
      this.handleUpdateSelectGift(data);
    },
    handleTriggerGiftPopup() {
      this.visible = true;
      this.handleUpdateSelectGift(this.gifts[0]);
    },
    handleSendGift() {
      // 钻石不够
      if (this.stoneNumber < this.selectedGift.price) {
        customShowConfirmDialog({
          message: this.t('cGiftPopup.钻石不足提醒'),
          cancelButtonText: this.t('cCustomDialog.取消'),
          confirmButtonText: this.t('cGiftPopup.去充值')
        }).then(() => this.handleLinkTo('/diamonds')).catch(e => void 0);
        return;
      }
      if (this.loading) {
        showToast({
          message: 'gift sending...'
        });
        return;
      }
      this.loading = true;

      // 发送礼物
      sendGift({
        anchorStringId: this.targetUserInfo.userStringId,
        giftId: this.selectedGift.id,
        sendScene: 0
      }).then(result => {
        if (result.code !== 0) {
          this.loading = false;
          return;
        }
        const custom = {
          vip: this.isVip ? '1' : '2'
        };
        const payload = {
          data: {
            giftUrl: this.selectedGift.image,
            giftId: this.selectedGift.id,
            giftName: this.selectedGift.name,
            giftPrice: this.selectedGift.price,
            giftDesc: this.selectedGift.desc,
            integral: this.selectedGift.score,
            anchorGiftName: this.selectedGift.name,
            anchorGiftDesc: this.selectedGift.desc,
            dynamicEffects: this.selectedGift.dynamicEffects
          },
          type: custemMessageType.GIFT
        };
        this.handleFetchStoneNumber();
        this.imInstance.handleSendCustomMessage('p2p', this.targetUserInfo.imId, payload, custom).then(() => {
          showToast({
            message: 'you send a gift!'
          });
          this.visible = false;
          this.loading = false;
        });
      });
    },
    handleLinkTo(link) {
      this.$router.push(link);
    }
  }
});