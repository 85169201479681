export default {
  取消: 'Cancel',
  确认: 'Confirm',
  VIP挽留弹窗内容: 'The beauty is still waiting for you Are you sure you want to give up your VIP status',
  VIP挽留弹窗取消: 'cruelly missed',
  VIP挽留弹窗确定: 'think again',
  钻石挽留弹窗内容一行: 'The beauty is still waiting for you to chat with her with diamonds',
  钻石挽留弹窗内容二行: 'Are you sure not to top up diamonds?',
  钻石挽留弹窗取消: 'cruelly missed',
  钻石挽留弹窗确定: 'think again',
  HI弹窗内容: 'You have already used 5 free greeting chances, buy VIP and chat freely',
  HI弹窗取消: 'Cancel',
  HI弹窗确定: 'Buy now',
};
