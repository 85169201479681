import { TOURIST_DEVICE_ID } from '@/utils/consts';
import { makeUUIDLikePhoneDeviceId } from '@/utils/utils';

export function makeTouristUniqueDeviceId() {
  return makeUUIDLikePhoneDeviceId();
}

export function getTouristUniqueDeviceId() {
  return window.localStorage.getItem(TOURIST_DEVICE_ID) || '';
}

export function saveTouristUniqueDeviceId(deviceId) {
  if (getTouristUniqueDeviceId()) {
    return;
  }
  window.localStorage.setItem(TOURIST_DEVICE_ID, deviceId);
}
