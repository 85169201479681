import { defineComponent, ref } from 'vue';
import { useIsVip } from '@/hooks/useIsVip';
// import { handleAutoPlayCallBell } from '@/utils/utils';
// import { IM_AUTHORIZATION } from '@/utils/consts';
import { getUserInfoFromStorage } from '@/utils/utils';
import { useDispatchVoiceEventListener } from '../hooks/useDispatchVoiceEventListener';
import ScaleView from './ScaleView.vue';
import CallOpecation from './CallOpecation.vue';
import TargetUserAvatar from './TargetUserAvatar.vue';
import TimeTool from '@/components/timing-tool/index.vue';

// let bellStoper = null;

export default defineComponent({
  name: 'rtc-audio-entry',
  components: {
    'scale-view': ScaleView,
    'call-opecation': CallOpecation,
    'target-user-info': TargetUserAvatar,
    'time-tool': TimeTool
  },
  setup() {
    const isSmallest = ref(false);
    const {
      isVip
    } = useIsVip();
    const {
      imInstance,
      targetUserInfo,
      visible,
      status,
      handleEndFakeReciveInvite,
      handleCancelFakeRoposeCall
    } = useDispatchVoiceEventListener();
    return {
      targetUserInfo,
      isSmallest,
      visible,
      imInstance,
      status,
      isVip,
      handleEndFakeReciveInvite,
      handleCancelFakeRoposeCall
    };
  },
  watch: {
    status(newValue, oldValue) {
      // if (newValue === 'initiate') {
      //   handleAutoPlayCallBell();
      // }
      // try {
      //   if (newValue === 'invited') {
      //     bellStoper = handleAutoPlayCallBell();
      //   }
      //   if (newValue !== 'invited' && bellStoper) {
      //     bellStoper();
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
      if (oldValue === 'calling' && newValue !== 'calling') {
        this.handleResumeAudioView();
      }
    }
  },
  methods: {
    handleSmallView() {
      this.isSmallest = true;
    },
    handleResumeAudioView() {
      this.isSmallest = false;
    },
    // 主动拒绝邀请
    handleCancelInvite() {
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleCancelFakeRoposeCall();
        return;
      }
      this.imInstance.handleCancelInvite();
    },
    // 接受邀请
    handleReciveInvited() {
      const userInfo = getUserInfoFromStorage();
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleEndFakeReciveInvite();
        this.imInstance.handleGetUserIfAllowCallInfo('voiceChat', userInfo.imId);
        return;
      }
      this.imInstance.handleReviceInvitedAndJoin();
    },
    // 拒绝邀请
    handleRejectInvited() {
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleEndFakeReciveInvite();
        return;
      }
      this.imInstance.handleRejectInvite();
    },
    // 挂断通话
    handleHangupCall() {
      this.imInstance.handleLeaveChannel();
    }
  }
});