export default {
  举报: 'report',
  拉黑: 'black',
  在线: 'online',
  个人信息: 'information',
  最近上线时间: 'last online time',
  恋爱观念: 'concept of love',
  交友目的: 'dating purpose',
  职业: 'job',
  生日: 'birthday',
  身高: 'height',
  体重: 'weight',
  相册: 'albums',
  视频: 'videos',
  打招呼: 'Hi',
  发消息: 'Message',
  视频通话: 'Video Call',
  Hi: 'Nice to meet you',
  开通VIP可查看: 'Open VIP to view all information',
  开通Vip: 'Open Vip',
  拉黑提示: 'black success',
  拉黑文案: 'block this user? They will not be able to see your personal information on bliss live',
};
