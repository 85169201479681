export default {
  标题: '消息',
  系统消息: '系统消息',
  数据为空: '暂无消息',
  解锁个人信息: '解锁个人信息',
  看看谁喜欢我: '看看谁喜欢我',
  VIP奢华标志: 'VIP奢华标志',
  语音视频聊天无限制: '语音视频聊天无限制',
  获取她的位置: '获取她的位置',
  解锁聊天限制: '解锁聊天限制',
  VIP: 'VIP',
};
