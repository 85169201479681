import network from '@/utils/network';

// 获取最新一条系统消息
export function handleGetLastSystemNotices(params) {
  return network.post('/notification/get-bell-msg', params);
}

// 获取消息列表
export function handleGetSystemNotices(params) {
  return network.post('/notification/get-message-list', params);
}

// 上报日志
export function handleKKReportLog(params) {
  return network.post('/event-tracking/report', params);
}

// 统计上报
export function handleKKStatisticsReport(params) {
  return network.post('/event-tracking/push-user-operate', params, {}, true);
}

// 进入页面统计上报
export function handleKKStatisticsReportWhenEntry(params) {
  return network.post('/event-tracking/push-user-click', params, {}, true);
}
