export default {
  标题: 'मेरा',
  消息阅读: 'नए संदेश, पढ़ने के लिए क्लिक करें!',
  会员: 'वीआईपी',
  钻石: 'डायमंड',
  访客: 'आगंतुकों',
  设置: 'सेटिंग',
  会员截止: 'सदस्यता की समय सीमा {time}',
  修改头像提示: 'अवतार की सफलता को संशोधित करें',
};
