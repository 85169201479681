import { Overlay } from 'vant';
import { makeI18nT } from '@/languages/index';
import { getPlatformType } from '@/utils/utils';
import { defineComponent, ref, watchEffect } from 'vue';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { opecationGuideForFacebookByIOS, opecationGuideForFacebookByAndroid } from './config';

import './opecationGuideFacebook.scss';

export default defineComponent({
  name: 'opecation-guide-facebook',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const visible = ref(false);

    const handleOverlayClick = () => {
      visible.value = false;
    };

    watchEffect(() => {
      visible.value = props.modelValue;
    });

    watchEffect(() => emit('update:modelValue', visible.value));

    useLockBodyScroll(visible);

    const { t } = makeI18nT('cAddToHomeScreen');

    return {
      visible, handleOverlayClick, t,
    };
  },
  render() {
    return (
      <Overlay z-index="199" lazyRender={false} show={this.visible} onClick={this.handleOverlayClick}>
        {this.handleRenderGuideContent()}
      </Overlay>
    );
  },
  methods: {
    handleRenderGuideContent() {
      return this.handleCreateGuideContent(getPlatformType() === 'ios' ? opecationGuideForFacebookByIOS : opecationGuideForFacebookByAndroid);
    },
    handleCreateGuideContent(setting) {
      return (
        <div className="ogi-container">
          <div className="ogi-container-title">
            <div>{this.t(`cAddToHomeScreen.${setting.title}`)}</div>
          </div>
          <div className="ogi-container-content">
            {setting.steps.map(this.handleCreateGuideItemContent.bind(this))}
          </div>
        </div>
      );
    },
    handleCreateGuideItemContent(item, index) {
      return (
        <div key={index} className="ogi-container-content-item">
          <div className="ogi-container-content-item-text">{this.t(`cAddToHomeScreen.${item.title}`)}</div>
          <div className="ogi-container-content-item-img">
            <img src={item.img} />
          </div>
        </div>
      );
    },
    setVisible(visible) {
      this.visible = visible;
    },
  },
});
