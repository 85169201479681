import { defineComponent, ref } from 'vue';
import { useIsVip } from '@/hooks/useIsVip';
// import { handleAutoPlayCallBell } from '@/utils/utils';
// import { IM_AUTHORIZATION } from '@/utils/consts';
import { getUserInfoFromStorage } from '@/utils/utils';
import { handleGetIsUserVideo, handleAddVideoNumber } from '@/services/social';
import { useDispatchVideoEventListener } from '../hooks/useDispatchVideoEventListener';
import ScaleView from './ScaleView.vue';
import CallOpecation from './CallOpecation.vue';
import TargetUserInfoByVideo from './TargetUserInfoByVideo.vue';
import GiftPopup from '@/components/gift-popup/index.vue';
import ToolBarByVideo from './ToolBarByVideo.vue';
import ChatWindowByVideo from './ChatWindowByVideo.vue';
import TimeTool from '@/components/timing-tool/index.vue';

// let bellStoper = null;

export default defineComponent({
  name: 'rtc-video-entry',
  components: {
    'scale-view': ScaleView,
    'gift-popup': GiftPopup,
    'call-opecation': CallOpecation,
    'target-user-info': TargetUserInfoByVideo,
    'tool-bar-by-video': ToolBarByVideo,
    'chat-window-by-video': ChatWindowByVideo,
    'time-tool': TimeTool
  },
  setup() {
    const fakePlay = ref(false);
    const isSmallest = ref(false);
    const {
      isVip
    } = useIsVip();
    const {
      imInstance,
      targetUserInfo,
      visible,
      status,
      coverVideoUrl,
      fakeCallVideoUrl,
      handleEndFakeReciveInvite,
      handleCancelFakeRoposeCall
    } = useDispatchVideoEventListener();
    return {
      targetUserInfo,
      visible,
      imInstance,
      status,
      isSmallest,
      handleEndFakeReciveInvite,
      handleCancelFakeRoposeCall,
      isVip,
      coverVideoUrl,
      fakeCallVideoUrl,
      fakePlay
    };
  },
  watch: {
    isSmallest(newValue) {
      this.$nextTick(() => {
        var _this$imInstance$agor;
        (_this$imInstance$agor = this.imInstance.agoraInstance.remoteTargetUser) === null || _this$imInstance$agor === void 0 || _this$imInstance$agor.videoTrack.play(!newValue ? 'rtc-video-output' : 'rtc-video-small-scale-view');
      });
    },
    status(newValue, oldValue) {
      // try {
      //   if (newValue === 'invited') {
      //     bellStoper = handleAutoPlayCallBell();
      //   }
      //   if (newValue !== 'invited' && bellStoper) {
      //     bellStoper();
      //   }
      // } catch (e) {
      //   console.log(e);
      // }

      if (oldValue === 'calling' && newValue !== 'calling') {
        this.handleResumeVideoView();
      }
    }
  },
  methods: {
    // 主动拒绝邀请
    handleCancelInvite() {
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleCancelFakeRoposeCall();
        return;
      }
      this.imInstance.handleCancelInvite();
      this.handleResumeVideoView();
    },
    // 接受邀请
    handleReciveInvited() {
      const userInfo = getUserInfoFromStorage();

      // 播放 15s 假视频
      if (this.fakeCallVideoUrl) {
        handleGetIsUserVideo().then(result => {
          if (result.code !== 0) {
            return;
          }
          if (!result.data.flag) {
            this.handleEndFakeReciveInvite();
            this.$router.push('/member');
            return;
          }
          this.fakePlay = true;
          this.$nextTick(() => {
            this.$refs.fakeCallVideo.muted = false;
            this.$refs.fakeCallVideo.addEventListener('ended', () => {
              this.fakePlay = false;
              this.handleEndFakeReciveInvite();
              this.$router.push('/member');
            });
            this.$refs.fakeCallVideo.play();
            handleAddVideoNumber();
          });
        });
        return;
      }

      // 结束此次假通话
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleEndFakeReciveInvite();
        this.imInstance.handleGetUserIfAllowCallInfo('videoChat', userInfo.imId);
        return;
      }
      this.imInstance.handleReviceInvitedAndJoin();
    },
    // 拒绝邀请
    handleRejectInvited() {
      if (this.imInstance.getInvitedInfos().isFake) {
        this.handleEndFakeReciveInvite();
        return;
      }
      this.imInstance.handleRejectInvite();
    },
    // 挂断通话
    handleHangupCall() {
      this.imInstance.handleLeaveChannel();
      this.handleResumeVideoView();
    },
    // 最小化
    handleResumeVideoView() {
      this.isSmallest = false;
    },
    handleSmallView() {
      this.isSmallest = true;
    }
  }
});