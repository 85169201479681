export default {
  标题: '修改密码',
  保存: '保存',
  新密码: '新的密码',
  输入密码占位文案: '请输入密码',
  密码提醒: '密码必须是 6-16 位的数字或字母',
  确认密码: '确认密码',
  确认密码描述: '新的密码必须和确认的密码相同',
  密码不同: '密码输入不一致',
  密码为空: '密码为空',
  密码格式错误: '密码格式错误',
  修改成功: '修改成功',
};
