import { useRouter } from 'vue-router';
import { defineComponent, onMounted, onActivated } from 'vue';
import { AUTHORIZATION, IM_AUTHORIZATION } from '@/utils/consts';
export default defineComponent({
  name: 'view-wraper',
  setup() {
    const router = useRouter();
    const handleLoginedChecking = () => {
      if (localStorage.getItem(AUTHORIZATION) && localStorage.getItem(IM_AUTHORIZATION)) {
        return;
      }
      if (['/sign-up', '/login'].indexOf(window.location.pathname) > -1 || /\/advertise\//.test(window.location.pathname)) {
        return;
      }
      router.replace('/sign-up');
    };
    onMounted(() => handleLoginedChecking());
    onActivated(() => handleLoginedChecking());
  }
});