import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-327edbdb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "rtc-audio-wrap"
};
const _hoisted_2 = {
  class: "rtc-audio"
};
const _hoisted_3 = {
  class: "rtc-audio-user"
};
const _hoisted_4 = {
  class: "rtc-audio-opecation"
};
const _hoisted_5 = {
  id: "rtc-audio-small-view",
  class: "rtc-audio-small-window"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_target_user_info = _resolveComponent("target-user-info");
  const _component_call_opecation = _resolveComponent("call-opecation");
  const _component_time_tool = _resolveComponent("time-tool");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_scale_view = _resolveComponent("scale-view");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_target_user_info, {
    targetUserInfo: _ctx.targetUserInfo
  }, null, 8, ["targetUserInfo"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_call_opecation, {
    type: "audio",
    status: _ctx.status,
    onOnReciveInvited: _ctx.handleReciveInvited,
    onOnCancelInvite: _ctx.handleCancelInvite,
    onOnRejectInvited: _ctx.handleRejectInvited,
    onOnHangupCall: _ctx.handleHangupCall
  }, null, 8, ["status", "onOnReciveInvited", "onOnCancelInvite", "onOnRejectInvited", "onOnHangupCall"])]), _ctx.status === 'calling' ? (_openBlock(), _createBlock(_component_time_tool, {
    key: 0
  })) : _createCommentVNode("", true)], 512), [[_vShow, !_ctx.isSmallest]]), _ctx.status === 'calling' ? (_openBlock(), _createBlock(_component_scale_view, {
    key: 0,
    modelValue: _ctx.isSmallest,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.isSmallest = $event),
    "handle-ele-id": "rtc-audio-small-view",
    onHandleClick: _ctx.handleResumeAudioView
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_van_icon, {
      name: "phone-circle"
    })])]),
    _: 1
  }, 8, ["modelValue", "onHandleClick"])) : _createCommentVNode("", true)], 512)), [[_vShow, _ctx.visible]]);
}