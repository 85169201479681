import { IMG_STATIC_DOMAIN } from '@/utils/consts';

export const opecationGuideForSafari = {
  title: '方便下次使用哦',
  steps: [
    { title: '第一步点击下方【分享】', img: `${IMG_STATIC_DOMAIN}/safari_step_1_en.png` },
    { title: '第二步点击【添加到主屏幕】', img: `${IMG_STATIC_DOMAIN}/safari_step_2_en.png` },
    { title: '第三步点击【添加】', img: `${IMG_STATIC_DOMAIN}/safari_step_3_en.png` },
  ],
};

export const opecationGuideForChrome = {
  title: '方便下次使用哦',
  steps: [
    { title: '第一步点击右上角【更多】', img: `${IMG_STATIC_DOMAIN}/chrome_step_1_en.png` },
    { title: '第二步点击【添加到主屏幕】', img: `${IMG_STATIC_DOMAIN}/chrome_step_2_en.png` },
    { title: '第三步点击【添加】', img: `${IMG_STATIC_DOMAIN}/chrome_step_3_en.png` },
  ],
};

export const opecationGuideForFacebookByIOS = {
  title: '打开内置浏览器',
  steps: [
    { title: '第一步点击右下角', img: `${IMG_STATIC_DOMAIN}/facebook_ios_guide_step_1_en.png` },
    { title: '第二步打开系统浏览器', img: `${IMG_STATIC_DOMAIN}/facebook_ios_guide_step_2_en.png` },
  ],
};

export const opecationGuideForFacebookByAndroid = {
  title: '打开内置浏览器',
  steps: [
    { title: '第一步点击右上角', img: `${IMG_STATIC_DOMAIN}/facebook_android_guide_step_1_en.png` },
    { title: '第二步打开系统浏览器', img: `${IMG_STATIC_DOMAIN}/facebook_android_guide_step_2_en.png` },
  ],
};
