import { defineComponent } from 'vue';
import { getUserInfoFromStorage } from '@/utils/utils';
import { handleInvokeGoogleTranslate } from '@/services/user';
import SvgIconfont from '@/components/iconfont/index.vue';
export default defineComponent({
  name: 'chat-window-record-by-video',
  props: ['targetUserInfo', 'data', 'violationText'],
  components: {
    'svg-iconfont': SvgIconfont
  },
  data() {
    const userInfo = getUserInfoFromStorage();
    return {
      loading: false,
      translateText: '',
      userInfo
    };
  },
  methods: {
    handleTranslateText(data) {
      if (this.translateText) {
        return;
      }
      this.loading = true;
      handleInvokeGoogleTranslate(data.text).then(result => {
        this.loading = false;
        this.translateText = result[0][0][0] || '';
      });
    }
  }
});