export default {
  标题: 'Message',
  系统消息: 'system notice',
  数据为空: 'Empty Messages',
  解锁个人信息: 'unlock personal information',
  看看谁喜欢我: 'see who likes me',
  VIP奢华标志: 'VIP luxury logo',
  语音视频聊天无限制: 'Unlimited voice and video chat',
  获取她的位置: 'get her location',
  解锁聊天限制: 'Unlock chat restrictions',
  VIP: 'VIP',
};
