export default {
  标题: '编辑信息',
  保存: '保存',
  昵称: '昵称',
  性别: '性别',
  关于你: '关于你',
  信息: '信息',
  身高: '身高',
  体重: '体重',
  性别男: '性别男',
  性别女: '性别女',
  确定: '确定',
  取消: '取消',
  请输入: '请输入...',
  修改成功提示: '修改成功',
};
