import { useImInstance } from '@/hooks/useImInstance';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import * as Comlink from 'comlink';
let timerTicker = null;
export default defineComponent({
  name: 'timing-tool',
  setup() {
    const count = ref(0);
    const {
      imInstance
    } = useImInstance();
    const handleStartTimer = async () => {
      if (!imInstance.value.comlinkWorker) {
        timerTicker = setInterval(() => count.value++, 1000);
        return;
      }
      await imInstance.value.comlinkWorker.handleStartTimer(Comlink.proxy(number => {
        count.value = number;
      }));
    };
    const handleStopTimer = async () => {
      if (!imInstance.value.comlinkWorker) {
        clearInterval(timerTicker);
      } else {
        await imInstance.value.comlinkWorker.handleStopTimer();
      }
      count.value = 0;
    };
    onMounted(() => handleStartTimer());
    onUnmounted(() => handleStopTimer());
    return {
      count
    };
  },
  computed: {
    timeText() {
      const [minute, second] = [Math.floor(this.count / 60), this.count % 60];
      return `${Math.max(minute, 0)}:${second >= 10 ? second : `0${second}`}`;
    }
  }
});