export default {
  视频消息: 'video message',
  语音消息: 'voice message',

  请输入: 'type a message...',
  非会员提示: 'she set up a message assistant and can receive your reply immediately',
  非会员按钮文案: 'Become VIP and start unlimited chat',
  松开结束: 'release to end',
  按住说话: 'hold to talk',

  你: 'you',
  希望: 'hope',
  给: 'Gave',
  送礼物: 'can give her a present',
  发送: 'Send',

  发送视频邀请: 'Send a video invitation',
  发送语音邀请: 'Send a audio invitation',
  等你: 'I am waiting for you~',
  回拨: 'Click to call back now',
  不支持回拨: 'The callback function is not supported at the moment, please check in the app',

  私照查看图片提示: 'Activate VIP view picture',
  取消: 'Cancel',
  确定: 'To VIP',
  当前钻石不足: 'Currently insufficient diamonds',
  隐私视频查看提示: 'Activate vip view video',

  话单拒绝: 'Rejected',
  话单取消: 'Cancelled',
  话单无应答: 'No answer',
  忙线: 'The other party is busy',
  当前通话中: 'You are currently on a call',

  接受位置: 'Access her location',

  私照提示: 'unlocking private photos requires {count} diamonds/piece',
  私照确认: 'Confirm',

  发送语音: 'Release send',
  取消发送语音: 'release to cancel',
  不支持播放语音: 'Voice is temporarily unavailable',

  云信推送不可送达: 'The other party\'s push is unreachable',
  对方云信不在线: 'The other party is not online',

  等待对方应答: 'waiting for answer...',
  邀请你语音通话: 'invites you to a voice chat...',
  邀请你视频通话: 'invites you to a video chat...',

  请开启音视频权限: 'Please enable audio and video permissions',
};
