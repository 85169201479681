export const routes = [{
  path: '/', redirect: '/sign-up',
}, {
  path: '/advertise/:platform',
  component: () => import('@/views/advertise/index.vue'),
}, {
  path: '/sign-up',
  component: () => import('@/views/sign-up/index.vue'),
}, {
  path: '/login',
  component: () => import('@/views/login/index.vue'),
}, {
  path: '/inited-completion',
  component: () => import('@/views/inited-completion/index.vue'),
}, {
  path: '/inited-avatar',
  component: () => import('@/views/inited-avatar/index.vue'),
}, {
  path: '/hot',
  meta: { keepAlive: true },
  component: () => import('@/views/hot/index.vue'),
}, {
  path: '/mine',
  component: () => import('@/views/mine/index.vue'),
}, {
  path: '/messages',
  meta: { keepAlive: true },
  component: () => import('@/views/messages/index.vue'),
}, {
  path: '/chat',
  component: () => import('@/views/chat/index.vue'),
}, {
  path: '/chat-more',
  component: () => import('@/views/chat-more/index.vue'),
}, {
  path: '/anchor',
  component: () => import('@/views/anchor/index.vue'),
}, {
  path: '/visitor',
  component: () => import('@/views/visitor/index.vue'),
}, {
  path: '/diamonds',
  component: () => import('@/views/diamonds/index.vue'),
}, {
  path: '/member',
  component: () => import('@/views/member/index.vue'),
}, {
  path: '/setting',
  component: () => import('@/views/setting/index.vue'),
}, {
  path: '/account',
  component: () => import('@/views/account/index.vue'),
}, {
  path: '/about',
  component: () => import('@/views/about/index.vue'),
}, {
  path: '/block-list',
  component: () => import('@/views/block-list/index.vue'),
}, {
  path: '/system-not',
  component: () => import('@/views/system-not/index.vue'),
}, {
  path: '/upload-photo',
  component: () => import('@/views/upload-photo/index.vue'),
}, {
  path: '/report',
  component: () => import('@/views/report/index.vue'),
}, {
  path: '/change-password',
  component: () => import('@/views/change-password/index.vue'),
}, {
  path: '/edit-infomation',
  component: () => import('@/views/edit-infomation/index.vue'),
}, {
  path: '/user-agreement',
  component: () => import('@/views/user-agreement/index.vue'),
}, {
  path: '/privacy-policy',
  component: () => import('@/views/privacy-policy/index.vue'),
}];
