import dayjs from 'dayjs';

import { showToast } from 'vant';
import { router } from '@/router/index';
import { getUserInfoFromStorage } from '@/utils/utils';
import { events, XLMessageTypeEnum, XLBillStatusEnum } from './config';
import { handleCheckoutIfAllowCall, handleConsumeStoneByCalling } from '@/services/diamonds';

import * as Comlink from 'comlink';

let checkTimer = null;
let inviteTimer = null;

export default class CoreXL {
  // 获取邀请信息
  getInvitedInfos() {
    return this.invitedInfos;
  }

  // 设置假的邀请消息 -> 策略信推送的信息
  handleSetFakeInvitedInfos() {
    Object.assign(this.invitedInfos, { isFake: true });
  }

  // 发起语音通话 => 创建通道 + 加入通道 + 邀请呼叫
  // 用户端发起拦截 => vip、钻石 拦截
  handleProposeVoiceOrVideoCall(type, targetUserInfo) {
    const userInfo = getUserInfoFromStorage();
    const extInfo = this.handleCreateCustomInfos(targetUserInfo);

    const params = {
      type,
      attachExt: JSON.stringify(extInfo),
      account: targetUserInfo.imId,
      requestId: Math.random().toString(),
      offlineEnabled: true,
      pushInfo: {
        pushTitle: this.vueI18nT(type === XLMessageTypeEnum.audio ? 'chat.视频消息' : 'chat.语音消息'),
        pushContent: this.vueI18nT(type === XLMessageTypeEnum.audio ? 'chat.视频消息' : 'chat.语音消息'),
        needPush: true,
        pushPayload: {
          sType: 'p2p', jump: 'chat', sId: userInfo.imId, date: +new Date(),
        },
      },
    };

    // 正在通话中
    if (this.getInvitedInfos().channelInfo?.channelId) {
      showToast({ message: this.vueI18nT('chat.当前通话中') });
      return Promise.resolve(true);
    }

    return this.handleGetUserIfAllowCallInfo(type === XLMessageTypeEnum.audio ? 'voiceChat' : 'videoChat', targetUserInfo.imId)
      .then(() => this.handleCheckAudioAndVideoPermissions({ audio: true, video: type !== XLMessageTypeEnum.audio }))
      .then(() => this.handleCheckNIMFuncInvoker())
      .then(() => this.nim.signalingCall(params))
      .then((result) => {
        Object.assign(this.invitedInfos, { type, channelInfo: { channelId: result.channelId }, fromAccid: userInfo.imId, toAccid: targetUserInfo.imId, requestId: params.requestId, attachExt: extInfo });
      })
      .then(() => {
        this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_PROPOSE_VOICE_CALL : events.XL_PROPOSE_VIDEO_CALL);
      })
      .then(() => {
        // 超时 30s 定时器
        inviteTimer = window.setTimeout(() => this.handleTimeoutCancelInvite(), 30 * 1000);
      })
      .catch((e) => {
        console.log(e, e.message);

        if (e.code && e.code > 10000) {
          this.handleProposeFakeCall(type, { type, channelInfo: { channelId: '' }, fromAccid: userInfo.imId, toAccid: targetUserInfo.imId, requestId: params.requestId, attachExt: extInfo });
        }
      });
  }

  // 调用假的通话
  handleProposeFakeCall(type, invitedInfos) {
    this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_FAKE_PROPOSE_VOICE_CALL : events.XL_FAKE_PROPOSE_VIDEO_CALL, invitedInfos);
    this.handleSetFakeInvitedInfos();
  }

  // 获取拦截权限相关信息
  handleGetUserIfAllowCallInfo(type, targetImId) {
    console.log('获取拦截权限相关信息: ', type, targetImId);

    // return Promise.resolve(true);

    return handleCheckoutIfAllowCall({ type, targetImId }).then((result) => {
      if (result.code !== 0) {
        return Promise.reject(new Error(result.msg));
      }
      // 非会员
      if (result.data.member === false) {
        return router.push('/member') && Promise.reject(new Error('allow call: is not vip'));
      }
      // 钻石不够
      if (result.data.enough === false) {
        return router.push('/diamonds') && Promise.reject(new Error('allow call: is not enough'));
      }
      return result.data;
    });
  }

  // 构建自定义信息
  handleCreateCustomInfos(targetUserInfo) {
    const userInfo = getUserInfoFromStorage();
    const channelName = `${userInfo.userId}${targetUserInfo.userId}${dayjs().unix()}`;

    return {
      callType: '0', callRtcType: '1', channelName, callerUserId: userInfo.userId, calleeUserId: targetUserInfo.userId,
    };
  }

  // 绑定信令通知
  handleInitNimNoticeListener() {
    this.handleCheckNIMFuncInvoker().then(() => {
      this.nim.on('signalingNotify', this.handleSignalingNotifyCallback.bind(this));
    });
  }

  // 统一接收到的信令通知
  handleSignalingNotifyCallback(event) {
    console.log('信令通知: ', event);

    if (event.eventType === 'INVITE') {
      this.handleSignalingInviteNotice(event);
    }
    if (event.eventType === 'ROOM_JOIN') {
      this.handleSignalingAcceptNotice(event);
    }
    if (event.eventType === 'CANCEL_INVITE') {
      this.handleCancelInviteNotice(event);
    }
    if (event.eventType === 'REJECT') {
      this.handleSignalingRejectNotice(event);
    }
    if (event.eventType === 'ROOM_CLOSE') {
      this.handleCloseRoomNotice(event);
    }
  }

  // 接收到邀请通知
  // 用户端接收到邀请拦截
  handleSignalingInviteNotice(event) {
    const { attachExt, channelId, requestId, from, to, type } = event;

    // 正在通话中 - 忙线
    if (this.invitedInfos.channelInfo) {
      this.handleRejectInviteByBusy(event);
      return;
    }

    const invitedInfos = {
      channelInfo: { channelId }, fromAccid: from, toAccid: to, requestId, attachExt: JSON.parse(attachExt), type: parseInt(type, 10),
    };

    Object.assign(this.invitedInfos, invitedInfos);
    this.handleDispatchCustomEvent(invitedInfos.type === XLMessageTypeEnum.audio ? events.XL_RECIVE_VOICE_INVITED : events.XL_RECIVE_VIDEO_INVITED);
  }

  // 接收邀请并加入
  handleReviceInvitedAndJoin() {
    const { channelInfo, requestId, fromAccid, toAccid, attachExt, type } = this.invitedInfos;

    const params = {
      channelId: channelInfo.channelId, account: fromAccid, requestId, offlineEnabled: false, autoJoin: true, attachExt: JSON.stringify(attachExt),
    };

    // 接受邀请 -> vip、钻石 拦截
    return this.handleGetUserIfAllowCallInfo(type === XLMessageTypeEnum.audio ? 'voiceChat' : 'videoChat', toAccid)
      .then(() => this.handleCheckAudioAndVideoPermissions({ audio: true, video: type !== XLMessageTypeEnum.audio }))
      .then(() => this.handleCheckNIMFuncInvoker())
      .then(() => this.nim.signalingAccept(params))
      .then(() => this[type === XLMessageTypeEnum.audio ? 'handleEntryVoiceCall' : 'handleEntryVideoCall'](attachExt.channelName, attachExt.calleeUserId))
      .catch((e) => {
        console.log(e, e.message);
        this.handleRejectInvite();
      });
  }

  // 进入RTC音频
  handleEntryVoiceCall(channelName, userId) {
    return this.agoraInstance.handleJoinAudioChannel(channelName, userId);
  }

  // 进入RTC视频
  handleEntryVideoCall(channelName, userId) {
    return this.agoraInstance.handleJoinVideoChannel(channelName, userId);
  }

  // 收到接收邀请通知 -> 进入 rtc 通道
  handleSignalingAcceptNotice() {
    const { attachExt, type } = this.invitedInfos;

    // 清除邀请超时定时器
    window.clearTimeout(inviteTimer);

    return this[type === XLMessageTypeEnum.audio ? 'handleEntryVoiceCall' : 'handleEntryVideoCall'](attachExt.channelName, attachExt.callerUserId);
  }

  // 轮训校验权限
  async handlePollingCheckAuth(type, userId) {
    console.log('轮训校验权限: ', type, userId);

    const { attachExt } = this.invitedInfos;

    const params = {
      type: type === XLMessageTypeEnum.audio ? 'voiceChat' : 'videoChat', targetUserId: userId, channelId: attachExt?.channelName,
    };

    // 优先使用后台线程
    if (this.comlinkWorker) {
      await this.comlinkWorker.handleStartBilling(params, Comlink.proxy(this.handleStartBillingCallBack.bind(this)));
      return;
    }

    // 回退处理
    this.handleRealConsumeStoneByCalling(params);
    checkTimer = window.setInterval(() => this.handleRealConsumeStoneByCalling(params), 1000 * 60);
  }

  handleStartBillingCallBack(result) {
    // 非 vip
    if (result.code === 35) {
      return this.handleLeaveChannel() && router.push('/member');
    }
    // 钻石不足
    if (result.code === 9) {
      return this.handleLeaveChannel() && router.push('/diamonds');
    }
    // 自定义的超时
    if (result.code === -1) {
      return this.handleLeaveChannel();
    }
    if (result.code === 0) {
      console.log('扣除钻石');
    }
    return null;
  }

  handleRealConsumeStoneByCalling(params) {
    // return Promise.resolve(true);
    return handleConsumeStoneByCalling(params).then(this.handleStartBillingCallBack.bind(this));
  }

  async handleStopPollingCheckAuth() {
    // 优先使用后台线程
    if (this.comlinkWorker) {
      await this.comlinkWorker.handleStopBilling();
      return;
    }

    if (!checkTimer) {
      return;
    }
    checkTimer = window.clearInterval(checkTimer) || null;
  }

  // 挂断退出频道 -> 先退云信、再退 rtc
  handleLeaveChannel() {
    const { type } = this.invitedInfos;

    this.handleStopPollingCheckAuth();

    return this.handleCheckNIMFuncInvoker()
      // .then(() => this.handleLeaveCallingChannel())
      .then(() => this.handleCloseLeftChannel())
      .then(() => this.agoraInstance[type === XLMessageTypeEnum.audio ? 'handleLeaveAudioChannel' : 'handleLeaveVideoChannel']())
      .then(() => this.handleResetInvitedInfos())
      .then(() => this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL));
  }

  // 离开频道
  handleLeaveCallingChannel() {
    const { channelInfo, attachExt } = this.invitedInfos;
    return this.handleCheckNIMFuncInvoker().then(() => channelInfo.channelId && this.nim.signalingLeave({ channelId: channelInfo.channelId, offlineEnabled: true, attachExt: JSON.stringify(attachExt) }));
  }

  // 关闭频道
  handleCloseLeftChannel() {
    const { channelInfo, attachExt } = this.invitedInfos;

    return this.handleCheckNIMFuncInvoker()
      .then(() => channelInfo.channelId && this.nim.signalingClose({ channelId: channelInfo.channelId, offlineEnabled: true, attachExt: JSON.stringify(attachExt) }))
      .catch((e) => console.log(e));
  }

  // 重置邀请相关的信息
  handleResetInvitedInfos() {
    Object.assign(this.invitedInfos, { channelInfo: null, fromAccid: '', toAccid: '', requestId: '', attachExt: null, type: '', isFake: false });
    this.handleResetLiveMessages();

    this.handleStopPollingCheckAuth();
  }

  // 发起者主动取消邀请 -> 发送取消话单
  handleCancelInvite() {
    const { requestId, channelInfo, toAccid, type, attachExt } = this.invitedInfos;

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.nim.signalingCancel({ requestId, channelId: channelInfo.channelId, account: toAccid, offlineEnabled: true, attachExt: JSON.stringify(attachExt) }))
      .then(() => this.handleLeaveCallingChannel())
      .then(() => this.handleCloseLeftChannel())
      .then(() => this.handleSendG2Message('p2p', toAccid, { channelId: 0, status: XLBillStatusEnum.cancel, type, durations: [] }, { rAccId: '', isSWCall: true, channelName: attachExt.channelName }))
      .then(() => this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL))
      .then(() => this.handleResetInvitedInfos())
      .catch((e) => {
        console.log('发起者主动取消邀请:', e);
        // 云信用户已接受邀请 -> 声网响应慢
        if (e.code === 10410) {
          this.handleCloseLeftChannel();
        }

        // 云信用户已关闭频道 -> 直接重置

        // 重置信息
        this.agoraInstance.handleCancelAgoraRTC(type);
        this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL);
        this.handleResetInvitedInfos();
      });
  }

  // 超时取消 -> 发送超时取消话单
  handleTimeoutCancelInvite() {
    const { requestId, channelInfo, toAccid, type, attachExt } = this.invitedInfos;

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.nim.signalingCancel({ requestId, channelId: channelInfo.channelId, account: toAccid, offlineEnabled: true, attachExt: JSON.stringify(attachExt) }))
      .then(() => this.handleLeaveCallingChannel())
      .then(() => this.handleCloseLeftChannel())
      .then(() => this.handleSendG2Message('p2p', toAccid, { channelId: 0, status: XLBillStatusEnum.timeout, type, durations: [] }, { rAccId: '', isSWCall: true, channelName: attachExt.channelName }))
      .then(() => this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL))
      .then(() => this.handleResetInvitedInfos());
  }

  // 收到取消邀请通知
  handleCancelInviteNotice() {
    const { type } = this.invitedInfos;

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.handleResetInvitedInfos())
      .then(() => this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL));
  }

  // 接收到拒绝邀请通知 -> 发送拒绝话单
  handleSignalingRejectNotice(event) {
    return this.handleCheckNIMFuncInvoker()
      .then(() => window.clearTimeout(inviteTimer))
      .then(() => this.handleLeaveCallingChannel())
      .then(() => this.handleCloseLeftChannel())
      .then(() => this.handleResetInvitedInfos())
      .then(() => {
        const attachExt = JSON.parse(event.attachExt);
        this.handleSendG2Message('p2p', event.from, { channelId: 0, status: attachExt.busy === 'i_am_busy' ? XLBillStatusEnum.busy : XLBillStatusEnum.reject, type: parseInt(event.type, 10), durations: [] }, { rAccId: '', isSWCall: true, channelName: event.channelName });
      })
      .then(() => {
        this.handleDispatchCustomEvent(parseInt(event.type, 10) === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL);
      });
  }

  // 拒绝邀请
  handleRejectInvite() {
    const { channelInfo, fromAccid, requestId, type, attachExt } = this.invitedInfos;

    return this.handleCheckNIMFuncInvoker()
      .then(() => this.nim.signalingReject({ channelId: channelInfo.channelId, account: fromAccid, requestId, offlineEnabled: true, attachExt: JSON.stringify(attachExt) }))
      .then(() => this.handleResetInvitedInfos())
      .then(() => this.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL));
  }

  // 忙线拒绝邀请
  handleRejectInviteByBusy(event) {
    console.log('忙线拒绝邀请', event);

    const { channelId, from, requestId } = event;
    return this.handleCheckNIMFuncInvoker().then(() => this.nim.signalingReject({ channelId, account: from, requestId, offlineEnabled: true, attachExt: JSON.stringify({ busy: 'i_am_busy', callRtcType: '1', rAccId: null }) }));
  }

  // 关闭房间通知
  handleCloseRoomNotice(event) {
    this.handleStopPollingCheckAuth();
    return this.handleCheckNIMFuncInvoker().then(() => this.handleDispatchCustomEvent(event.type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL));
  }
}
