import { reactive } from 'vue';

export function useParamsUpdate(defaultParams) {
  const params = reactive(defaultParams);

  const handleUpdateParams = (willMergedParams) => {
    Object.assign(params, willMergedParams);
  };

  return {
    params, handleUpdateParams,
  };
}
