/* eslint-disable prefer-promise-reject-errors */
export const SUCCESS = 10000;
export const OPEN_ERROR = 10001;
export const REQUEST_ERROR = 10003;
export const TRANSACTION_ERROR = 10002;

export class CTIndexedDB {
  constructor(setting) {
    this.validate();

    this.handler = null;
    this.setting = setting;
  }

  validate() {
    if (typeof this.getIndexedDB() !== 'undefined') {
      return;
    }
    throw new Error('the browser no supper indexedDB');
  }

  getIndexedDB() {
    return window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
  }

  connect() {
    const that = this;

    return new Promise((resolve, reject) => {
      const request = this.getIndexedDB().open(that.setting.database, that.setting.version);

      request.onsuccess = () => {
        if (!that.handler) {
          that.handler = request.result;
        }
        resolve(that);
      };

      request.onupgradeneeded = () => {
        that.createObjectStore(request.result, that.setting.stores);
      };

      request.onerror = (error) => {
        reject({ code: OPEN_ERROR, description: `${that.setting.database}：${that.setting.version} 数据库打开失败`, message: error.message, data: null });
      };
    });
  }

  createObjectStore(database, stores) {
    for (let i = 0, len = stores.length; i < len; i++) {
      if (database.objectStoreNames.contains(stores[i].name)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const store = database.createObjectStore(stores[i].name, { keyPath: stores[i].keyPath });

      for (let j = 0, cLen = stores[i].cursorIndex.length; j < cLen; j++) {
        store.createIndex(stores[i].cursorIndex[j].name, stores[i].cursorIndex[j].name, { unique: stores[i].cursorIndex[j].unique });
      }
    }
  }

  insertData(storeName, datas) {
    const that = this;

    return new Promise((resolve, reject) => {
      const transaction = that.handler.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);

      const insertedDatas = [].concat(datas);

      for (let i = 0, len = insertedDatas.length; i < len; i++) {
        store.put(insertedDatas[i]);
      }

      transaction.oncomplete = () => {
        resolve({ code: SUCCESS, description: 'success', message: null, data: insertedDatas.length });
      };

      transaction.onerror = () => {
        reject({ code: TRANSACTION_ERROR, description: `${storeName}: 插入数据失败`, message: transaction.error, data: null });
      };
    });
  }

  getData(storeName, fieldIndex, value) {
    const that = this;

    return new Promise((resolve, reject) => {
      const transaction = that.handler.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);

      const request = typeof value !== 'undefined' ? store.index(fieldIndex).get(value) : store.get(fieldIndex);

      transaction.onerror = () => {
        reject({ code: TRANSACTION_ERROR, description: `${storeName}: 获取数据失败`, message: transaction.error, data: null });
      };

      request.onerror = () => {
        reject({ code: REQUEST_ERROR, description: `${storeName}: 获取数据失败`, message: request.error, data: null });
      };

      request.onsuccess = () => {
        resolve({ code: SUCCESS, description: 'success', message: null, data: request.result });
      };
    });
  }

  updateData(storeName, where, data) {
    return this.getData(storeName, ...where).then((result) => this.insertData(storeName, { ...result.data, ...data }));
  }

  deleteData(storeName, primaryKeyValue) {
    const that = this;

    return new Promise((resolve, reject) => {
      const transaction = that.handler.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);

      const request = store.delete(primaryKeyValue);

      transaction.onerror = () => {
        reject({ code: TRANSACTION_ERROR, description: `${storeName}: 删除失败`, message: transaction.error, data: null });
      };

      request.onerror = () => {
        reject({ code: REQUEST_ERROR, description: `${storeName}: 删除失败`, message: request.error, data: null });
      };

      request.onsuccess = () => {
        resolve({ code: SUCCESS, description: 'success', message: null, data: 1 });
      };
    });
  }

  getAllData(storeName) {
    const that = this;

    return new Promise((resolve, reject) => {
      const transaction = that.handler.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);

      const request = store.getAll();

      transaction.onerror = () => {
        reject({ code: TRANSACTION_ERROR, description: `${storeName}: 获取所有数据失败`, message: transaction.error, data: null });
      };

      request.onerror = () => {
        reject({ code: REQUEST_ERROR, description: `${storeName}: 获取所有数据失败`, message: request.error, data: null });
      };

      request.onsuccess = () => {
        resolve({ code: SUCCESS, description: 'success', message: null, data: request.result });
      };
    });
  }

  clearDB(storeName) {
    const that = this;

    return new Promise((resolve, reject) => {
      const transaction = that.handler.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);

      const request = store.clear();

      transaction.onerror = () => {
        reject({ code: TRANSACTION_ERROR, description: `${storeName}: 清空所有数据失败`, message: transaction.error, data: null });
      };

      request.onerror = () => {
        reject({ code: REQUEST_ERROR, description: `${storeName}: 清空所有数据失败`, message: request.error, data: null });
      };

      request.onsuccess = () => {
        resolve({ code: SUCCESS, description: 'success', message: null, data: request.result });
      };
    });
  }

  destroy() {
    if (!this.handler) {
      return;
    }

    this.handler.close();

    this.handler = null;
    this.setting = undefined;
  }
}
