export default {
  标题: '设置',
  账号和安全: '账号和安全',
  关于: '关于 Bliss Live',
  评分: '评分',
  黑名单: '黑名单',
  切换用户: '切换用户',
  切换用户弹窗标题: '切换用户',
  切换用户弹窗内容: '请保存你的id和密码，方便下次登陆',
  切换用户弹窗取消: '取消',
  切换用户弹窗确认: '确认',
};
