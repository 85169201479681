// eslint-disable-next-line import/no-extraneous-dependencies
import { render, h } from 'vue';
import { showToast } from 'vant';
import { v4 as uuidv4 } from 'uuid';
import { makeI18nT } from '@/languages/index';
import { USER_INFO, AD_PLATFORM, AD_PLATFORM_REFFER } from './consts';

export function makeUUIDLikePhoneDeviceId() {
  return uuidv4();
}

export function getSystemLanguage() {
  return 'en-US';
  // return navigator.language ? navigator.language.toLocaleLowerCase() : '';
}

export function handleRecordMicrophoneAudio() {
  if (typeof navigator.mediaDevices === 'undefined') {
    return Promise.reject(new Error('your browser not supper navigator.mediaDevices'));
  }
  return navigator.mediaDevices.getUserMedia({ video: false, audio: true })
    .then(async (stream) => {
      const recorder = window.RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/wav',
      });

      recorder.startRecording();

      return () => new Promise((resolve) => {
        recorder.stopRecording(() => resolve(recorder.getBlob()));
      });
    })
    .catch((e) => {
      console.log(e.message);

      const { t } = makeI18nT('chat');
      showToast({ message: t('chat.请开启音视频权限') });

      return Promise.reject(e);
    });
}

export function getUserInfoFromStorage() {
  const [userInfo, userInfoString] = [{}, window.localStorage.getItem(USER_INFO) || null];

  try {
    Object.assign(userInfo, JSON.parse(userInfoString));
  } catch (e) {
    console.log(e);
  }
  return userInfo;
}

export function isMobile() {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(window.navigator.userAgent);
}

export function isInFacebook() {
  return /FBSV|FBAV/.test(window.navigator.userAgent);
}

export function getPlatformType() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'ios';
  }
  if (/(Android)/i.test(navigator.userAgent)) {
    return 'android';
  }
  return '';
}

export function getBrowserType() {
  if (navigator.userAgent.indexOf('UCBrowser') !== -1) {
    return 'uc';
  }
  if (window.chrome || window.isChrome) {
    return 'chrome';
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'safari';
  }
  return '';
}

export function throttle(fn, delay) {
  let timer;
  return (...args) => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      fn.apply(this, args);
      timer = null;
    }, delay);
  };
}

export function handleTriggerRenderToBody(Component, props, id) {
  const element = document.createElement('div');

  element.id = id || `add-to-body-${Math.random()}`;

  document.body.appendChild(element);

  const instance = h(Component, props);

  render(instance, element);

  const closer = () => {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'visible';
    }

    render(null, element);
    document.body.removeChild(element);
  };

  closer.instance = instance;

  return closer;
}

export function injectMixinClass(AopClass) {
  const handleMixinProps = (target, source) => {
    Object.getOwnPropertyNames(source).forEach((prop) => {
      if (/^constructor$/.test(prop)) {
        return;
      }
      Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
    });
  };
  return (TargetClass) => {
    handleMixinProps(TargetClass.prototype, AopClass.prototype);
    return TargetClass;
  };
}

export function handleFocusJump(jumpUrl, isFlagBeforeloadTip = false) {
  if (isFlagBeforeloadTip) {
    window.focusBeforeLoadTip = true;
  }
  window.location.href = jumpUrl;
}

export function noop() {}

export function handleTryParseJSON(content) {
  const data = {};

  try {
    Object.assign(data, JSON.parse(content));
  } catch (e) {
    console.log(e);
  }
  return data;
}

export function handleGetScrollTop() {
  if (document.documentElement && typeof document.documentElement.scrollTop !== 'undefined') {
    return document.documentElement.scrollTop || 0;
  }
  if (document.body) {
    return document.body.scrollTop || 0;
  }
  return 0;
}

export function handleSetScrollTop(scrollTop) {
  if (document.documentElement && typeof document.documentElement.scrollTop !== 'undefined') {
    document.documentElement.scrollTop = scrollTop;
    return;
  }

  if (document.body) {
    document.body.scrollTop = scrollTop;
  }
}

export function handleAutoPlayCallBell() {
}

export function handleAutoPlayMsgBell() {
}

export function isFromFullScreen() {
  if (getPlatformType() === 'ios') {
    return navigator.standalone;
  }
  return window.matchMedia('(display-mode: standalone)').matches;
}

export function handleForbidMobileScaleEvents() {
  // 禁用双指放大
  document.documentElement.addEventListener('touchstart', (event) => event.touches.length > 1 && event.preventDefault(), { passive: false });

  // 禁用双击放大
  const touchEndAndClickEndTimes = [0, 0];

  const touchEndAndClickEndHandler = (event, index) => {
    const now = Date.now();

    if (now - touchEndAndClickEndTimes[index] <= 300) {
      event.preventDefault();
    }
    touchEndAndClickEndTimes[index] = now;
  };

  document.documentElement.addEventListener('click', (event) => touchEndAndClickEndHandler(event, 1), { passive: false });
  document.documentElement.addEventListener('touchend', (event) => touchEndAndClickEndHandler(event, 0), { passive: false });
}

export function handleGetQuery(field) {
  const query = {};
  const queryStringList = window.location.search.slice(1).split('&');

  for (let i = 0, len = queryStringList.length; i < len; i++) {
    const [key, value] = queryStringList[i].split('=');
    query[key] = value;
  }
  return field ? query[field] : query;
}

export function handleGetEntryScenes() {
  const scenes = window.sessionStorage.getItem(AD_PLATFORM) || window.sessionStorage.getItem(AD_PLATFORM_REFFER) || 'kkweb';

  return isFromFullScreen() ? 'kkapp' : scenes;
}
