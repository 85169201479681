import { handleGetGifts } from '@/services/gift';
import { ref, onMounted, computed } from 'vue';

export function useGifts() {
  const gifts = ref([]);

  const handleFetchGistList = () => {
    handleGetGifts().then((result) => {
      gifts.value = result.code !== 0 ? [] : result.data.map((item, index) => ({ ...item, selected: index === 0 }));
    });
  };

  const handleUpdateSelectGift = (gift) => {
    gifts.value = gifts.value.map((item) => ({ ...item, selected: item.id === gift.id }));
  };

  const giftGroups = computed(() => {
    const groups = [];

    gifts.value.forEach((gift) => {
      if (groups.length === 0 || groups[groups.length - 1].length === 8) {
        groups.push([gift]);
        return;
      }
      groups[groups.length - 1].push(gift);
    });
    return groups;
  });

  const selectedGift = computed(() => gifts.value.find((item) => item.selected) || null);

  onMounted(() => handleFetchGistList());

  return {
    gifts, selectedGift, giftGroups, handleUpdateSelectGift,
  };
}
