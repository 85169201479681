export default {
  标题: 'जानकारी',
  数据为空: 'कोई समाचार नहीं',
  解锁个人信息: 'व्यक्तिगत जानकारी अनलॉक करें',
  看看谁喜欢我: 'देखो कौन मुझे पसंद करता है',
  VIP奢华标志: 'वीआईपी लक्जरी लोगो',
  语音视频聊天无限制: 'असीमित आवाज और वीडियो चैट',
  获取她的位置: 'उसका स्थान प्राप्त करें',
  解锁聊天限制: 'चैट प्रतिबंध अनलॉक करें',
  VIP: 'वीआईपी',
};
