import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15014797"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-popup"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "gift-title"
};
const _hoisted_4 = {
  class: "gift-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_gift_swipter = _resolveComponent("gift-swipter");
  const _component_gift_footer = _resolveComponent("gift-footer");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "gift-icon",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleTriggerGiftPopup && _ctx.handleTriggerGiftPopup(...arguments);
    })
  }, [_createElementVNode("img", {
    src: require('@/images/anchor/anchor_gift.png'),
    alt: "gift"
  }, null, 8, _hoisted_2)]), _createVNode(_component_van_popup, {
    show: _ctx.visible,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => _ctx.visible = $event),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('cGiftPopup.标题')), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_gift_swipter, {
      giftGroups: _ctx.giftGroups,
      onOnSelect: _ctx.handleSelect
    }, null, 8, ["giftGroups", "onOnSelect"])]), _createVNode(_component_gift_footer, {
      stoneNumber: _ctx.stoneNumber,
      onOnSendGift: _ctx.handleSendGift
    }, null, 8, ["stoneNumber", "onOnSendGift"])]),
    _: 1
  }, 8, ["show"])]);
}