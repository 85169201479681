export default {
  一键注册: 'One-click registration',
  注册同意: 'Registration means that you have read and agreed to the platform',
  用户协议: 'User Agreement',
  和: 'and',
  隐私协议: 'Privacy Policy',
  google: 'google',
  facebook: 'facebook',
  account: 'account',
};
