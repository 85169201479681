export default {
  取消: '取消',
  确认: '确认',
  VIP挽留弹窗内容: '美女还在等着你确定要放弃VIP身份吗',
  VIP挽留弹窗取消: '残忍错过',
  VIP挽留弹窗确定: '再想想',
  钻石挽留弹窗内容一行: '美女还在等你拿钻石陪她聊天',
  钻石挽留弹窗内容二行: '确定不充值钻石？',
  钻石挽留弹窗取消: '残忍错过',
  钻石挽留弹窗确定: '再想想',
  HI弹窗内容: '您已使用5次免费问候机会，购买VIP畅聊',
  HI弹窗取消: '取消',
  HI弹窗确定: '立刻购买',
};
