import { defineComponent } from 'vue';
import GiftItem from './GiftItem.vue';
export default defineComponent({
  name: 'gift-swiper',
  props: ['giftGroups'],
  components: {
    'gift-item': GiftItem
  },
  methods: {
    handleSelect(data) {
      this.$emit('onSelect', data);
    }
  }
});