export default {
  标题: 'Account And Securty',
  账号: 'User Id',
  密码: 'Password',
  修改密码: 'Change Password',
  Facebook: 'Facebook',
  Google: 'Google',
  绑定: 'unlink',
  未绑定: 'link',
  账号已绑定: 'The {platform} account has been bound',
};
