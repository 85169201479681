import { Overlay } from 'vant';
import { makeI18nT } from '@/languages/index';
import { SAVE_TO_HOMR_OK } from '@/utils/consts';
import { defineComponent, ref, watchEffect } from 'vue';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { KKStatisticsReport, KK_STATISTICS_EVENTS } from '@/providers/reporter';

import './enterTipAndroid.scss';

export default defineComponent({
  name: 'enter-tip-android',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const visible = ref(false);

    const handleOverlayClick = () => {
      visible.value = false;
    };

    watchEffect(() => {
      visible.value = props.modelValue;
    });

    watchEffect(() => emit('update:modelValue', visible.value));

    useLockBodyScroll(visible);

    const { t } = makeI18nT('cAddToHomeScreen');

    return {
      visible, handleOverlayClick, t,
    };
  },
  render() {
    return (
      <Overlay z-index="199" show={this.visible} onClick={this.handleOverlayClick}>
        {this.handleRenderModalContent()}
      </Overlay>
    );
  },
  methods: {
    handleRenderModalContent() {
      return (
        <div className="enter-tip-android" onClick={(event) => event.stopPropagation()}>
          <div className="eta-title">{this.t('cAddToHomeScreen.保存图标')}</div>
          <div className="eta-content">
            <div className="eta-content-text">{this.t('cAddToHomeScreen.保存到桌面来获得更佳的体验')}</div>
            <div className="eta-content-btns">
              <div className="eta-content-btn-item" onClick={this.handleCancel}>{this.t('cAddToHomeScreen.取消')}</div>
              <div className="eta-content-btn-item ok" onClick={this.handleOk}>{this.t('cAddToHomeScreen.确定')}</div>
            </div>
          </div>
        </div>
      );
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      KKStatisticsReport(KK_STATISTICS_EVENTS.ENTRY_MODAL_SAVE);

      if (!window.deferredPrompt) {
        this.visible = false;
        return;
      }
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((result) => {
        if (result.outcome !== 'accepted') {
          return;
        }
        // 已保存至主屏幕标识
        window.localStorage.setItem(SAVE_TO_HOMR_OK, '1');
      });
      this.visible = false;
    },
  },
});
