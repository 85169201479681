// 默认配置参数
export const defaultOptions = {
  db: false, // 不开启SDK本地数据库支持
  logLevel: 'debug', // 错误级别日志
  quickReconnect: true,
};

// 绑定回调函数
export const nimNeedBindEventMap = {
  onerror: 'handleErrorCallback',
  onmsg: 'handleMsgCallback',
  onofflinemsgs: 'handleOfflineMsgCallback',
  onconnect: 'handleConnectCallback',
  onwillreconnect: 'handleWillReconnect',
  onsessions: 'handleSessionsCallback',
  onupdatesession: 'handleUpdateSessionCallback',
  ondisconnect: 'handleDisconnectCallback',
  oncustomsysmsg: 'handleCustomSysMsgCallback',
};

// 文案常量
export const finalStatusTexts = {
  connecting: '连接 NIM 服务器...',
  connectFail: '连接 NIM 服务器失败, 暂时不可用',
};

// 信令类型
export const XLMessageTypeEnum = {
  audio: 1,
  video: 2,
};

// 话单类型
export const XLBillStatusEnum = {
  normal: 1,
  cancel: 2,
  reject: 3,
  timeout: 4,
  busy: 5,
};

// 发布订阅事件
export const events = {
  IM_UPDATE_MESSAGES: 'IM_UPDATE_MESSAGES',
  IM_UPDATE_LIVE_MESSAGES: 'IM_UPDATE_LIVE_MESSAGES',
  IM_UPDATE_SESSIONS: 'IM_UPDATE_SESSIONS',
  IM_UPDATE_SYSTEM_MESSAGES: 'IM_UPDATE_SYSTEM_MESSAGES',

  XL_PROPOSE_VOICE_CALL: 'XL_PROPOSE_VOICE_CALL',
  XL_FAKE_PROPOSE_VOICE_CALL: 'XL_FAKE_PROPOSE_VOICE_CALL',
  XL_RECIVE_VOICE_INVITED: 'XL_RECIVE_VOICE_INVITED',
  XL_FAKE_RECIVE_VOICE_INVITED: 'XL_FAKE_RECIVE_VOICE_INVITED',
  XL_ENTRY_VOICE_CALL: 'XL_ENTRY_VOICE_CALL',
  XL_END_VOICE_CALL: 'XL_END_VOICE_CALL',

  XL_PROPOSE_VIDEO_CALL: 'XL_PROPOSE_VIDEO_CALL',
  XL_FAKE_PROPOSE_VIDEO_CALL: 'XL_FAKE_PROPOSE_VIDEO_CALL',
  XL_RECIVE_VIDEO_INVITED: 'XL_RECIVE_VIDEO_INVITED',
  XL_FAKE_RECIVE_VIDEO_INVITED: 'XL_FAKE_RECIVE_VIDEO_INVITED',
  XL_ENTRY_VIDEO_CALL: 'XL_ENTRY_VIDEO_CALL',
  XL_END_VIDEO_CALL: 'XL_END_VIDEO_CALL',
};

export const notifyEnum = {
  IM_TEXT_VIOLATION: '1003',
};
