import { defineComponent } from 'vue';
import { makeI18nT } from '@/languages/index';
import { useImInstance } from '@/hooks/useImInstance';
import { useParamsUpdate } from '@/hooks/useParamsUpdate';
export default defineComponent({
  name: 'call-opecation',
  props: ['status', 'type'],
  setup() {
    const {
      t
    } = makeI18nT('chat');
    const {
      imInstance
    } = useImInstance();
    const {
      params,
      handleUpdateParams
    } = useParamsUpdate({
      openVoice: false,
      closeMicrophone: false
    });
    return {
      params,
      handleUpdateParams,
      imInstance,
      t
    };
  },
  computed: {
    statusText() {
      if (this.status === 'initiate') {
        return this.t('chat.等待对方应答');
      }
      if (this.status === 'invited') {
        return this.t(this.type === 'audio' ? 'chat.邀请你语音通话' : 'chat.邀请你视频通话');
      }
      return '';
    }
  },
  methods: {
    handleCancelInvite() {
      return void 0 || this.$emit('onCancelInvite');
    },
    handleReciveInvited() {
      return void 0 || this.$emit('onReciveInvited');
    },
    handleRejectInvited() {
      return void 0 || this.$emit('onRejectInvited');
    },
    handleHangupCall() {
      return void 0 || this.$emit('onHangupCall');
    },
    handleTriggerVoiceChange(openVoice) {
      this.handleUpdateParams({
        openVoice: !openVoice
      });
      this.imInstance.agoraInstance.handleSetRemoteUserVoice(!openVoice ? 200 : 100);
    },
    handleTriggerMicrophoneVoiceChange(closeMicrophone) {
      this.handleUpdateParams({
        closeMicrophone: !closeMicrophone
      });
      this.imInstance.agoraInstance.handleSetLocalVoice(!closeMicrophone ? 0 : 100);
    }
  }
});