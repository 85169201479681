/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/**
 * 网易 IM 通信 + 云信令
 */
import CoreIM from './CoreIM';
import CoreXL from './CoreXL';
import CoreAgora from './CoreAgora';
import EventEmitter from 'eventemitter3';

import { showToast } from 'vant';
import { makeI18nT } from '@/languages/index';
import { getIMIndexdb } from '@/database/index';
import { injectMixinClass } from '@/utils/utils';
import { getRequestHeaders } from '@/utils/network';
import { KKReporter, KK_EVENTS } from '@/providers/reporter';
import { defaultOptions, nimNeedBindEventMap, finalStatusTexts } from './config';

import * as Comlink from 'comlink';

@injectMixinClass(CoreIM)
@injectMixinClass(CoreXL)
export default class CoreWYIMBase extends EventEmitter {
  constructor() {
    super();

    // 参数保存
    this.options = {};

    // im 消息相关
    this.nim = null;
    this.status = '';
    this.database = getIMIndexdb() ? getIMIndexdb().connect() : null; // 本地数据库支持
    this.messages = []; // 消息列表
    this.liveMessages = []; // 直播消息列表
    this.flagMessages = []; // 标记的消息列表
    this.sessions = []; // 会话列表
    this.targetAccountId = null; // 当前聊天对象

    // 邀请相关的信息
    this.invitedInfos = {
      channelInfo: null, fromAccid: '', toAccid: '', requestId: '', attachExt: '', type: '', isFake: false,
    };

    // 声网
    this.agoraInstance = new CoreAgora(this);

    // 流程控制
    this.readly = null;
    this.relove = null;

    // 国际化处理
    this.vueI18nT = makeI18nT(['chat']).t;

    // 后台线程
    this.comlinkWorker = null;

    // dom 组件
    this.RtcAudioDOM = null;
    this.RtcVideoDOM = null;
  }

  init(options) {
    this.handleInitOptions(options);

    this.handleInitNimInstance();
    this.handleInitLocalSessions();
    this.handleInitNimNoticeListener();
    this.handleInitComlinkWork();
  }

  handleInitOptions(options) {
    Object.assign(this.options, options);
  }

  async handleInitComlinkWork() {
    if (!window.Worker) {
      console.error('no supper worker ....');
      return;
    }

    this.comlinkWorker = Comlink.wrap(new window.Worker(new URL('../web-worker.js', import.meta.url)));

    await this.comlinkWorker.init(process.env.VUE_APP_API_DOMAIN, getRequestHeaders());
  }

  // 初始话 nim 实例
  handleInitNimInstance() {
    const options = {
      ...defaultOptions, ...this.options, ...this.handleAdapteEventCallbacks(),
    };

    console.log('===> the nim init options: ', { ...options });

    this.readly = new Promise((resove) => {
      this.nim = window.NIM.getInstance(options);
      this.relove = resove;
    });
  }

  // 回调事件适配
  handleAdapteEventCallbacks() {
    const bindEventCallbacks = {};

    for (const prop in nimNeedBindEventMap) {
      bindEventCallbacks[prop] = this[nimNeedBindEventMap[prop]].bind(this);
    }
    return bindEventCallbacks;
  }

  // 连接回调
  handleConnectCallback({ connectionId }) {
    console.log('===> the nim connected success: ', connectionId);

    KKReporter(KK_EVENTS.IM_CONNECTED, { message: `connectionId: ${connectionId}` });

    if (connectionId) {
      this.status = 'connected';
    }
    this.relove(true);
  }

  // 连接断开
  handleDisconnectCallback(result) {
    console.log('im disconnected', result);
    KKReporter(KK_EVENTS.IM_DISCONNECTED, { message: result });
  }

  // 检查IM可用状态
  handleCheckNIMFuncInvoker() {
    if (this.status === 'connected') {
      return Promise.resolve(true);
    }
    return console.info(finalStatusTexts.connecting) || this.readly;
  }

  // 派发事件
  handleDispatchCustomEvent(eventName, ...args) {
    this.emit(eventName, ...args);
  }

  // 检查开启音视频权限
  handleCheckAudioAndVideoPermissions(medias) {
    if (typeof navigator.mediaDevices === 'undefined') {
      return Promise.reject(new Error('your browser not supper navigator.mediaDevices'));
    }
    const options = typeof medias !== 'undefined' ? medias : { video: true, audio: true };

    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia(options)
        .then(() => resolve(true))
        .catch((e) => {
          console.log(e);
          showToast({ message: this.vueI18nT('chat.请开启音视频权限') });
          reject(new Error(this.vueI18nT('chat.请开启音视频权限')));
        });
    });
  }
}
