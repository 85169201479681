export default {
  标题: 'खाता और सुरक्षा',
  账号: 'उपयोगकर्ता पहचान',
  密码: 'पासवर्ड',
  修改密码: 'पासवर्ड बदलें',
  Facebook: 'फेसबुक',
  Google: 'गूगल',
  绑定: 'जोड़ना',
  未绑定: 'अनलिंक',
  账号已绑定: '{platform} खाता बाध्य कर दिया गया है',
};
