export default {
  保存桌面图标: 'Save Desktop Icon',
  方便下次使用哦: 'Save desktop icons for easy next use',
  '第一步点击下方【分享】': 'Step 1: Click on 【Share】 below',
  '第一步点击右上角【更多】': 'Step 1: Click on the top right corner 【More】',
  '第二步点击【添加到主屏幕】': 'Step 2: Click on 【Add to Home Screen】',
  '第三步点击【添加】': 'Step 3: Click on 【Add】',

  保存到桌面来获得更佳的体验: 'Save to desktop for a better experience',
  取消: 'Cancel',
  确定: 'Confirm',

  保存图标: 'Save Desktop Icon',

  '7天内不再提醒': 'No more reminders within 7 days',
  正在尝试创建桌面图标: 'is attempting to create a desktop icon',

  打开内置浏览器: 'Open with a browser for a better experience',
  第一步点击右下角: 'Step 1: Click on 【···】 in the bottom right corner',
  第一步点击右上角: 'Step 1: Click on 【···】 in the upper right corner',
  第二步打开系统浏览器: 'Step 2: Click on 【Open in system browser】',
};
