import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2a10d126"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-item-icon"
};
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = {
  class: "gift-price"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "gift-item-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['gift-item', _ctx.data.selected ? 'selected' : '']),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleSelect(_ctx.data))
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.data.image,
    alt: _ctx.data.name
  }, null, 8, _hoisted_2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    class: "gift-price-icon",
    src: require('@/images/mine/mine_diamond_2.png'),
    alt: "price"
  }, null, 8, _hoisted_4), _createElementVNode("div", null, _toDisplayString(_ctx.data.price), 1)]), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.name), 1)], 2);
}