import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { Lazyload, Locale } from 'vant';
import { router } from '@/router/index';
import { getSystemLanguage } from '@/utils/utils';

import drag from 'v-drag';
import App from './App.vue';
import Bootstrap from '@/providers/bootstrap';

import HiIN from 'vant/es/locale/lang/hi-IN';
import EnUs from 'vant/es/locale/lang/en-US';

import './registerServiceWorker';

const app = createApp(App);

const locale = getSystemLanguage();

const libraryLanguage = {
  'hi-IN': HiIN, 'en-US': EnUs,
};

Locale.use(locale, libraryLanguage[locale]);

app.use(createI18n({ legacy: false, locale })).use(router).use(Lazyload).use(drag);

new Bootstrap().init().then((bootstrap) => app.provide('$bootstrap', bootstrap).mount('#app'));
