/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import network from '@/utils/network';

import { showFailToast } from 'vant';
import { router } from '@/router/index';
import { handleTriggerSayHiLimitDialog } from '@/components/custom-dialog/index';

// 获取 hot 列表
export function handlePopularList(params) {
  return network.post('/social/user/get-popular-list', params);
}

// sayHi
// 1. 非 vip 用户，每日 5 次免费打招呼 2. vip 用户无限制
let sayHiloading = false;

export function handleSayHi(params) {
  if (sayHiloading) {
    return Promise.resolve({ code: -1 });
  }

  sayHiloading = true;

  return network.post('/social/user/hello', params, {}, true).then((result) => {
    sayHiloading = false;

    if (result.code === 14) {
      return handleTriggerSayHiLimitDialog().then(() => router.push('/member')).then(() => ({})).catch(() => ({}));
    }
    if (result.code !== 0) {
      showFailToast({ message: result.msg, wordBreak: 'break-word' });
    }
    return result;
  });
}

// 心跳
export function handleOnlineUpdateState(params) {
  return network.post('/online/update-state', params);
}

// 获取访客列表
export function handleLookmeList() {
  return network.post('/social/user/get-lookme-list', {});
}

// 获取相册列表
export function handleGetUserImages(params) {
  return network.post('/social/user/image/get-my-images', params);
}

// 上传用户相册照片
export function handleUploadUserImage(params) {
  const formData = new FormData();

  for (const prop in params) {
    formData.append(prop, params[prop]);
  }
  return network.post('/social/user/image/upload-image', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

// 删除用户相册照片
export function handleDelUserImages(params) {
  return network.post('/social/user/image/del-image', params);
}

// 获取用户私照配置
export function handleGetUserPrivatePhotoConfig(params) {
  return network.post('/social/user/image/get-private-image-config', params);
}

// 15s 免费视频
export function handleGetIsUserVideo() {
  return network.post('/social/is-user-video', {});
}

// 15s 免费视频统计
export function handleAddVideoNumber() {
  return network.post('/social/add-video-number', {});
}
