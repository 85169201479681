import { IMG_RESOURCE_DOMAIN } from '@/utils/consts';

export const chatIconTypes = ['input', 'audio', 'add'];

export const chatExtIcons = [
  { type: 'gifts', title: 'Gifts', src: `${IMG_RESOURCE_DOMAIN}/chat_gift_icon.png`, icon: 'chat-gift' },
  { type: 'espression', title: 'Espression', src: `${IMG_RESOURCE_DOMAIN}/chat_emo_icon.png`, icon: 'chat-espression' },
  { type: 'photo', title: 'Photo', src: `${IMG_RESOURCE_DOMAIN}/chat_pic_icon.png`, icon: 'chat-photo' },
  { type: 'calling', title: 'Calling', src: `${IMG_RESOURCE_DOMAIN}/chat_call_icon.png`, icon: 'chat-phone' },
  { type: 'video', title: 'Video', src: `${IMG_RESOURCE_DOMAIN}/chat_video_icon.png`, icon: 'chat-video' },
];

export const custemMessageType = {
  QA: '100',
  QA_REPLAY: '101',
  GIFT: '102',
  RECIVED_AUDIO_OR_VIDEO: '103',
  PRIVATE_PHOTO: '104',
  ASK_GIFT: '107',
  MAP: '109',
  AUTO_PLAY_VIDEO: '111',
  BAKE_PRIVATE_PHOTO: '131',
  BAKE_PRIVATE_VIDEO: '132',
  REPORT_NOTICE: '112',
};
