import { SAVE_TO_HOMR_OK, IS_SHOW_SAVE_TO_HOMR } from '@/utils/consts';
import { getPlatformType, handleTriggerRenderToBody, isFromFullScreen, isInFacebook } from '@/utils/utils';

import Cookies from 'js-cookie';
import QuitTipAndroid from './quitTipAndroid';
import EnterTipAndroid from './enterTipAndroid';
import OpecationGuideIos from './opecationGuideIos';
import OpecationGuideFacebook from './opecationGuideFacebook';

// 进入 hot 页面 -> 提示弹窗
export function handleTriggerAddToHomeDialog() {
  let closer = null;

  const props = {
    modelValue: true, 'onUpdate:modelValue': (show) => !show && closer && closer(),
  };

  if (window.localStorage.getItem(SAVE_TO_HOMR_OK) === '1' || getPlatformType() !== 'android' || isFromFullScreen() || isInFacebook()) {
    return closer;
  }

  const id = 'add-to-home-dialog';

  if (document.getElementById(id)) {
    return closer;
  }
  closer = handleTriggerRenderToBody(EnterTipAndroid, props, id);

  return closer;
}

// android 离开页面 -> 显示弹窗
export function handleTriggerAddToHomeDialogOnQuitByAndroid() {
  let androidQuitCloser = null;

  return (visible) => {
    const props = {
      modelValue: visible,
    };

    if (window.localStorage.getItem(SAVE_TO_HOMR_OK) === '1' || Cookies.get(IS_SHOW_SAVE_TO_HOMR) === '1' || isFromFullScreen() || isInFacebook()) {
      return;
    }

    const id = 'add-to-home-dialog-by-android';

    if (document.getElementById(id)) {
      androidQuitCloser.instance.component.ctx.setVisible.call(androidQuitCloser.instance.component.ctx, visible);
      return;
    }

    androidQuitCloser = handleTriggerRenderToBody(QuitTipAndroid, props, id);
  };
}

// ios 离开页面 -> 显示弹窗
export function handleTriggerAddToHomeDialogOnQuitByIOS() {
  let iosQuitCloser = null;

  return (visible) => {
    const props = {
      modelValue: visible,
    };

    if (window.localStorage.getItem(SAVE_TO_HOMR_OK) === '1' || isFromFullScreen() || isInFacebook()) {
      return;
    }

    const id = 'add-to-home-dialog-by-ios';

    if (document.getElementById(id)) {
      iosQuitCloser.instance.component.ctx.setVisible.call(iosQuitCloser.instance.component.ctx, visible);
      return;
    }

    iosQuitCloser = handleTriggerRenderToBody(OpecationGuideIos, props, id);
  };
}

// facebook app -> 引导弹窗
export function handleTriggerGuideDialogByFacebook() {
  let guideFacebookCloser = null;

  return (visible) => {
    const props = {
      modelValue: visible,
    };

    if (window.localStorage.getItem(SAVE_TO_HOMR_OK) === '1' || isFromFullScreen() || !isInFacebook()) {
      return;
    }

    const id = 'facebook-guide-dialog';

    if (document.getElementById(id)) {
      guideFacebookCloser.instance.component.ctx.setVisible.call(guideFacebookCloser.instance.component.ctx, visible);
      return;
    }
    guideFacebookCloser = handleTriggerRenderToBody(OpecationGuideFacebook, props, id);
  };
}
