export default {
  标题: '举报',
  选择问题类型: '选择问题类型',
  上传证明: '上传证明',
  输入框占位: '请描述你的问题',
  继续按钮: '继续',
  Erotic: '情色',
  Advertising: '广告',
  Other: '其他',
  'Violence/Verbal Assult': '暴力/口头攻击',
  举报成功: '举报成功',
  请添加描述: '请添加描述',
};
