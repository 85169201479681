import { ref, onMounted } from 'vue';
import { handleGetStoneNumber } from '@/services/diamonds';

export function useDiamonds() {
  const stoneNumber = ref(0);

  const handleFetchStoneNumber = () => {
    handleGetStoneNumber().then((result) => {
      stoneNumber.value = result.code === 0 ? result.data.stone : 0;
    });
  };

  onMounted(() => handleFetchStoneNumber());

  return {
    stoneNumber, handleFetchStoneNumber,
  };
}
