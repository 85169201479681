/* eslint-disable no-underscore-dangle */
import { cancelable } from 'cancelable-promise';
import { XLMessageTypeEnum, events } from './config';
import { getUserInfoFromStorage } from '@/utils/utils';
import { handleGetAgoraAuthToken } from '@/services/user';
import { KKReporter, KK_EVENTS } from '@/providers/reporter';

export default class CoreAgora {
  constructor(dispatcher) {
    this.client = null;
    this.localAudioTrack = null;
    this.localVideoTrack = null;

    // 远端用户
    this.remoteTargetUser = null;

    // 依赖
    this.dispatcher = dispatcher;

    // 当前状态
    this.status = 'readly'; // readly -> fetch-token-start -> fetch-token-end -> joined -> publish -> calling -> unsubscribe -> readly

    this.init();
    this.joinPromise = null;
  }

  init() {
    this.handleCreateRTCClient().handleAddRemoteListener();
  }

  handleCreateRTCClient() {
    // eslint-disable-next-line no-return-assign
    return (this.client = window.AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })) && this;
  }

  handleAddRemoteListener() {
    // 用户离开 rtc 频道 -> 离开并关闭云信通道、离开 rtc 频道、退出语音通话界面
    this.client.on('user-left', this.handleUserLeftChannelListener.bind(this));

    // 用户进入 rtc 频道 -> 开始扣费计时
    this.client.on('user-joined', this.handleUserJoinChannelListener.bind(this));

    // 用户发布视频
    this.client.on('user-published', this.handleUserPublishedListener.bind(this));

    // 监听连接异常
    this.client.on('connection-state-change', this.handleConnectionStateListener.bind(this));

    // 网络状态监听
    // this.client.on('network-quality', this.handleNetworkQualityListener.bind(this));

    // 通道异常监听
    // this.client.on('exception', this.handleChannelExceptionListener.bind(this));
  }

  // 用户离开频道 -> 处理本地音轨、视频轨道
  handleUserLeftChannelListener() {
    const { type } = this.dispatcher.getInvitedInfos();

    return this.dispatcher.handleCheckNIMFuncInvoker()
      // .then(() => this.dispatcher.handleCloseLeftChannel())
      .then(() => (type === XLMessageTypeEnum.audio ? this.handleLeaveAudioChannel() : this.handleLeaveVideoChannel()))
      .then(() => this.dispatcher.handleResetInvitedInfos())
      .then(() => this.dispatcher.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_END_VOICE_CALL : events.XL_END_VIDEO_CALL));
  }

  // 处理用户同时进入 rtc
  handleUserJoinChannelListener() {
    const userInfo = getUserInfoFromStorage();
    const { type, fromAccid, attachExt } = this.dispatcher.getInvitedInfos();

    this.handleUpdateFlowStatus('calling');

    return this.dispatcher.handleCheckNIMFuncInvoker()
      .then(() => this.dispatcher.handleDispatchCustomEvent(type === XLMessageTypeEnum.audio ? events.XL_ENTRY_VOICE_CALL : events.XL_ENTRY_VIDEO_CALL))
      .then(() => this.dispatcher.handlePollingCheckAuth(type, userInfo.imId === fromAccid ? attachExt.calleeUserId : attachExt.callerUserId));
  }

  // 用户发布视频
  async handleUserPublishedListener(user, mediaType) {
    await this.client.subscribe(user, mediaType);

    console.log('subscribe success');

    // 保存远端用户
    this.remoteTargetUser = user;

    if (mediaType === 'audio') {
      user.audioTrack.play();
    }

    if (mediaType === 'video') {
      user.videoTrack.play(document.getElementById('rtc-video-small-scale-view') || document.getElementById('rtc-video-output'));
    }

    // eslint-disable-next-line no-shadow
    this.client.on('user-unpublished', this.handleUserUnPublishedListener.bind(this));
  }

  // 连接状态监听
  handleConnectionStateListener(curState, revState, reason) {
    const invitedInfos = { ...this.dispatcher.getInvitedInfos() };

    if (curState === 'DISCONNECTED' && reason === 'NETWORK_ERROR') {
      this.handleUserLeftChannelListener();
    }
    KKReporter(KK_EVENTS.AGORA_CHANNEL_STATUS, { curState, revState, reason, invitedInfos });
  }

  // 取消发布监听
  async handleUserUnPublishedListener(user, mediaType) {
    if (mediaType === 'audio' && this.localVideoTrack) {
      return;
    }
    if (mediaType === 'video') {
      const container = document.getElementById('rtc-video-output');
      const smallView = document.getElementById('rtc-video-small-scale-view');

      if (container) {
        container.innerHTML = '';
      }
      if (smallView) {
        smallView.innerHTML = '';
      }
    }
    await this.client.unsubscribe(user);
    this.handleUpdateFlowStatus('unsubscribe');
  }

  // 网络状态监听
  // handleNetworkQualityListener(stats) {
  //   console.log('网络状态监听: downlinkNetworkQuality', stats.downlinkNetworkQuality);
  //   console.log('网络状态监听: uplinkNetworkQuality', stats.uplinkNetworkQuality);
  // }

  // 通道异常监听
  // handleChannelExceptionListener(evt) {
  //   console.log('网络状态监听: ', evt.code, evt.msg, evt.uid);
  // }

  // 重置事件监听
  handleResetEventLister() {
    this.client.removeAllListeners();
    this.handleAddRemoteListener();
  }

  // 更新状态
  handleUpdateFlowStatus(status) {
    this.status = status;
  }

  // 加入音频频道
  handleJoinAudioChannel(channelName, userId) {
    this.handleUpdateFlowStatus('fetch-token-start');

    this.joinPromise = cancelable(this.handleGetAuthToken(channelName, userId)
      .then((authToken) => this.handleUpdateFlowStatus('fetch-token-end') || authToken)
      .then((authToken) => this.client.join(process.env.VUE_APP_AGORA_APPKEY, channelName, authToken, userId))
      .then(() => this.handleUpdateFlowStatus('joined'))
      .then(() => window.AgoraRTC.createMicrophoneAudioTrack())
      .then((localAudioTrack) => {
        this.localAudioTrack = localAudioTrack;
        return this.client.publish([this.localAudioTrack]);
      })
      .then(() => this.handleUpdateFlowStatus('publish'))
      .then(() => console.log('publish success!'))
      .catch((e) => console.log(e.message)));

    return this.joinPromise;
  }

  // 加入视频频道
  handleJoinVideoChannel(channelName, userId) {
    this.handleUpdateFlowStatus('fetch-token-start');

    this.joinPromise = cancelable(this.handleGetAuthToken(channelName, userId)
      .then((authToken) => this.handleUpdateFlowStatus('fetch-token-end') || authToken)
      .then((authToken) => this.client.join(process.env.VUE_APP_AGORA_APPKEY, channelName, authToken, userId))
      .then(() => this.handleUpdateFlowStatus('joined'))
      .then(() => Promise.all([window.AgoraRTC.createMicrophoneAudioTrack(), window.AgoraRTC.createCameraVideoTrack({ facingMode: 'user' })]))
      .then(([localAudioTrack, localVideoTrack]) => {
        this.localAudioTrack = localAudioTrack;
        this.localVideoTrack = localVideoTrack;

        return this.client.publish([this.localAudioTrack, this.localVideoTrack]);
      })
      .then(() => this.handleUpdateFlowStatus('publish'))
      .then(() => setTimeout(() => this.localVideoTrack.play(document.getElementById('rtc-video-small-view')), 500))
      .then(() => console.log('publish success!'))
      .catch((e) => console.log(e)));

    return this.joinPromise;
  }

  // 获取声网 Token
  handleGetAuthToken(channelName, userId) {
    return handleGetAgoraAuthToken({ agoraUid: userId, channelName }).then((result) => {
      if (result.code !== 0) {
        return '';
      }
      return result.data.agoraAuthToken;
    });
  }

  // 离开音频频道
  handleLeaveAudioChannel() {
    this.localAudioTrack?.close();
    this.client?.leave();
    this.handleUpdateFlowStatus('readly');
  }

  // 离开视频频道
  handleLeaveVideoChannel() {
    this.localAudioTrack?.close();
    this.localVideoTrack?.close();

    document.getElementById('rtc-video-small-view').innerHTML = '';
    this.client?.leave();
    this.handleUpdateFlowStatus('readly');
  }

  // 设置音量
  handleSetLocalVoice(voiceValue) {
    if (!this.localAudioTrack) {
      return;
    }
    console.log('handleSetLocalVoice: ', voiceValue);
    this.localAudioTrack.setVolume(voiceValue);
  }

  // 设置远端用户音量
  handleSetRemoteUserVoice(voiceValue) {
    if (!this.remoteTargetUser) {
      return;
    }
    this.remoteTargetUser.audioTrack?.setVolume(voiceValue);
  }

  // 反转摄像头
  async handleInvertCamera() {
    const { facingMode } = this.localVideoTrack._config;

    this.localVideoTrack.close();

    await this.client.unpublish(this.localVideoTrack);

    this.localVideoTrack = await window.AgoraRTC.createCameraVideoTrack({ facingMode: facingMode === 'user' ? 'environment' : 'user' });

    setTimeout(() => this.localVideoTrack.play(document.getElementById('rtc-video-small-view')), 500);

    await this.client.publish(this.localVideoTrack);
  }

  // 取消声网流程
  handleCancelAgoraRTC(type) {
    if (['readly'].indexOf(this.status) > -1) {
      return;
    }

    // 取消加入声网的流程 -> 重新绑定事件相关
    if (['fetch-token-start', 'fetch-token-end'].indexOf(this.status) > -1) {
      this.joinPromise?.cancel();
      this.handleResetEventLister();
      this.handleUpdateFlowStatus('readly');
      return;
    }

    if (['join', 'publish', 'calling', 'unsubscribe'].indexOf(this.status) > -1) {
      // eslint-disable-next-line consistent-return
      return type === XLMessageTypeEnum.audio ? this.handleLeaveAudioChannel() : this.handleLeaveVideoChannel();
    }
  }
}
