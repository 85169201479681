import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aed75bb4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "chat-window-by-video",
  ref: "liveChatWindow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_chat_window_record_by_video = _resolveComponent("chat-window-record-by-video");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.liveMessages, item => {
    return _openBlock(), _createBlock(_component_chat_window_record_by_video, {
      key: item.idClient,
      targetUserInfo: _ctx.targetUserInfo,
      data: item,
      violationText: _ctx.getViolationText(item)
    }, null, 8, ["targetUserInfo", "data", "violationText"]);
  }), 128))], 512);
}