export default {
  标题: 'Edit Information',
  保存: 'Save',
  昵称: 'name',
  性别: 'gender',
  关于你: 'About you',
  信息: 'INFO',
  身高: 'height',
  体重: 'weight',
  性别男: 'Male',
  性别女: 'Femail',
  确定: 'Confirm',
  取消: 'Cancel',
  请输入: 'please enter here...',
  修改成功提示: 'change success',
};
