/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import network from '@/utils/network';

import { getSystemLanguage } from '@/utils/utils';

// 通过云信 imId 获取用户信息列表
export function handleBatchGetUserInfosByYXIMIds(params) {
  return network.post('/social/user/batch-get-detail-imids', params);
}

// 通过 userStringId 获取用户信息
export function handleGetUserInfoByUserStringId(params) {
  return network.post('/social/user/get-detail', params);
}

// 获取声网鉴权token
export function handleGetAgoraAuthToken(params) {
  return network.post('/account/user/get-agora-auth-token', params);
}

// 修改密码
export function handleModifyPassword(params) {
  return network.post('/account/user/modify-password', params);
}

// 设置密码
export function handleSetPassword(params) {
  return network.post('/account/user/set-password', params);
}

// 获取绑定信息
export function handleGetThirdBindInfos() {
  return network.post('/account/third-party/get-bind', {}, {}, true);
}

// 修改用户头像
export function handleModifyAvatar(params) {
  const formData = new FormData();

  for (const prop in params) {
    formData.append(prop, params[prop]);
  }
  return network.post('/account/user/modify-icon', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

// 获取自己的用户信息
export function handleGetUserInfo(params) {
  return network.post('/account/user/get-detail', params);
}

// 获取自己的用户信息
export function handleGetUserInfoBySlice() {
  return network.post('/account/user/get-detail', {}, {}, true);
}

// 修改自己的用户信息
export function handleModifyUserInfo(params) {
  return network.post('/account/user/modify-detail', params);
}

// 获取一键问候用户列表
export function handleGetGreetUsers() {
  return network.post('/account/user/get-register-guide', {}, {}, true);
}

// 翻译
export function handleInvokeGoogleTranslate(text) {
  const tl = getSystemLanguage();
  return fetch(`${process.env.VUE_APP_GOOGLE_TRANSLATE_PREFIX}/translate_a/single?client=gtx&sl=auto&tl=${tl}&dt=t&q=${window.encodeURIComponent(text)}`).then((response) => response.json());
}

// 绑定 google
export function handleBindGoogle(params) {
  return network.post('/account/third-party/bind/google', params);
}

// 绑定 facebook
export function handleBindFacebook(params) {
  return network.post('/account/third-party/bind/facebook', params);
}
