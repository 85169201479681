export default {
  标题: 'Setting',
  账号和安全: 'Account And Security',
  关于: 'About Bliss Live',
  评分: 'Rate Us',
  黑名单: 'Blocked List',
  切换用户: 'Switch User',
  切换用户弹窗标题: 'Switch Account',
  切换用户弹窗内容: 'please save you id and password to login again',
  切换用户弹窗取消: 'Cancel',
  切换用户弹窗确认: 'Confirm',
};
