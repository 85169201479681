export default {
  标题: 'प्रतिवेदन',
  选择问题类型: 'प्रश्न प्रकार चुनें',
  上传证明: 'प्रमाणपत्र अपलोड करें',
  输入框占位: 'कृपया उस समस्या का वर्णन करें जिसका आप सामना कर रहे हैं',
  继续按钮: 'जारी रखना',
  Erotic: 'कामुक',
  Advertising: 'विज्ञापन देना',
  Other: 'अन्य',
  'Violence/Verbal Assult': 'हिंसा/मौखिक हमला',
  举报成功: 'सफल रिपोर्ट',
  请添加描述: 'कृपया अपना विवरण दर्ज करें',
};
